@media (min-width: 1100px) and (max-width: 1280px) {

  .addshipment{
    overflow: auto;
  }
  .rascroll{
    overflow: inherit;
  }
  .housbill{
    width: 68%;
  }
  .airbill div:nth-child(6) .form-select{
    width: 130px;
  }
  .rasteper{
    padding-left: 0;
  }
  .raroot .MuiStepLabel-alternativeLabel{
    margin-left: 10px;
  }
  .raroot .MuiStepConnector-root{
    left: 47px !important;
  }
  .housbilltxt label:nth-child(1) {
    margin-right: 49px;
}

}

@media (min-width: 1300px) and (max-width: 1440px) {
  .SecondDiv{
    width: 40% !important;
  }
  .airbill  div:nth-child(1)  input {
    width: 120px !important;
  }
  .airbill  div:nth-child(4)  select {
    width: 62px !important;
  }
  .airbill  div:nth-child(5)  select {
    width: 80px !important;
  }
  .airbill  div:nth-child(7)  select {
    width: 80px !important;
  }

  .intact_mt div:nth-child(4)  select {
    width: 85px !important;
  }

  .intact_mt div:nth-child(5)  select {
    width: 130px !important;
  }
  .rasteper{
    padding: 40px 0px 40px 0;
  }
  .raroot .MuiStepLabel-alternativeLabel{
    margin-left: 10px;
  }
  .raroot .MuiStepConnector-root{
    left: 48px !important;
  }
  .housbilltxt label:nth-child(1) {
    margin-right: 58px;
}
.housbilltxt label:nth-child(2) {
  margin-right: 55px;
}

.rauldstack  div:nth-child(1)  input.form-control {
  width: 120px !important;
}
.rauldstack  div:nth-child(2)  input.form-control {
  width: 120px !important;
}
.rauldstack  div:nth-child(3)  input.form-control {
  width: 120px !important;
}
.rauldstack  div:nth-child(4)  input.form-control {
  width: 120px !important;
}
.rauldstack  div:nth-child(5)  select.form-select {
  width: 120px !important;
}
.housbill{
  width: 416px;
}
.housbilltxt{
  margin-left: 33px;
}
.stepper-frms .col-xxl-4{
  width: 50%;
}

.table-responsive{
  overflow-x: auto;
}

.from_width {
  width: 100%;
}

}

@media (min-width: 1660px) and (max-width: 2500px) {

  .stting_left{
    width: 20% !important;
  }
  .stting_right{
    width: 80% !important;
  }
    .stepper-frms{
      padding: 0 32px;
    }
    .stepper-frm-info{
      padding: 0 25px;
    }
    .housbill{
      width: 47%;
    }
    .airbill  div:nth-child(1)  input:first-of-type{
      width: 60px !important;
    }
    .airbill  div:nth-child(1)  input:last-of-type{
      width: 150px !important;
    }
    /* .airbill  div:nth-child(2)  input {
      width: 80px !important;
    } */
    .airbill  div:nth-child(2)  input {
      width: 80px !important;
    }
    .airbill  div:nth-child(3)  input {
      width: 90px !important;
    }
    .airbill  div:nth-child(4)  select {
      width: 140px !important;
    }
    .airbill  div:nth-child(5)  select {
      width: 140px !important;
    }
    .airbill  div:nth-child(6)  select {
      width: 170px !important;
    }
    .rauldstack  div:nth-child(1)  input.form-control {
      width: 160px !important;
    }

    .rauldstack  div:nth-child(2)  input.form-control {
      width: 160px !important;
    }
    .rauldstack  div:nth-child(3)  input.form-control {
      width: 160px !important;
    }
    .rauldstack  div:nth-child(4)  input.form-control {
      width: 160px !important;
    }
    .rauldstack  div:nth-child(5)  select.form-select {
      width: 180px !important;
    }


    .housbill label{ 
      font-size: 13px !important;
    }
    .scrollable-calendar{
      justify-content: center;
    }
    .avalable{
      display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    }
    .billtable table th{
      font-size: 14px;
      padding: 5px;
    }
    .accordion-body {
      padding: 10px !important;
  }
  .airbill .form-control, .airbill .form-select{
    height: 32px;
  }
  .addshipment .form-control{
    height: 32px;
  }
  .airbill label{
    font-size: 13px !important;
  }
  .infobox .card-body{
    padding: 12px !important;
  }
  .raclose{
    right: 10px !important;
  }
  .housbilltxt{
    margin-left: 34px;
  }
  .housbilltxt label:nth-child(1) {
    margin-right: 50px;
}
.housbilltxt label:nth-child(2) {
  margin-right: 55px;
}

.addshipment .form-floating>.form-control:focus~label,
.addshipment .form-floating>.form-control:not(:placeholder-shown)~label,
.addshipment .form-floating>.form-select~label {
  opacity:1;
  transform: scale(.85) translateY(-.95rem) translateX(.15rem);
  background: #fff;
  height: 30px;
}

  }

  @media (min-width: 2502px){

    
    .st-f{
      height: 92vh;
    }
    .raroot .MuiStepLabel-alternativeLabel{
      font-size: clamp(1rem, 2.5vw, 1.5rem);

    }
    .stepper-frms{
      h4{
        font-size: clamp(1rem, 2.5vw, 1.5rem);
      }

      h2{
        font-size: clamp(1rem, 2.5vw, 1.5rem);
      }
      
    } 
    .stepper-frms .form-label{
      font-size: clamp(1rem, 2.5vw, 1.2rem) !important;
    }
    .raroot .MuiStepConnector-root {
      top: -82px !important;
      left: 55px !important;
      width: 10px;
  }
  .raroot .MuiStepConnector-line {
    width: 2px;
    height: 100px;
    background: #6b95d5;
}
.raroot{
  margin-bottom: 65px;
}
.stepper-frms .form-select{
  padding: 0.95rem 0.75rem;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
}
.form-check label{
  font-size: clamp(1rem, 2.5vw, 1.1rem);
}
.rastp{
  font-size: clamp(1rem, 2.5vw, 1.1rem);
}
.infobox h2{
  font-size: clamp(1rem, 2.5vw, 1.3rem);
}
.infobox ul li span{
  font-size: clamp(1rem, 2.5vw, 1.2rem) !important;
}
.stepper-frms .form-control{
  padding: 0.85rem 0.75rem;
  font-size: clamp(1rem, 2.5vw, 1.4rem);
}
.rmsc .dropdown-heading{
  height: 55px !important;
}
.rmsc .dropdown-heading .dropdown-heading-value{
  padding: 0.95rem 0.75rem;
}
.addshipment .form-control {
  height: 45px;
}
.airbill .form-control, .airbill .form-select {
  height: 45px;
}
.airbill .form-control, .airbill .form-select{
  font-size: 1.1rem;

}
.airbill div:nth-child(1) input:first-of-type {
  width: 80px !important;
}
.airbill div:nth-child(1) input:last-of-type {
  width: 150px !important;
}
.airbill div:nth-child(2) input {
  width: 120px !important;
}
.airbill div:nth-child(3) input {
  width: 90px !important;
}
.airbill div:nth-child(4) input {
  width: 100px !important;
}
.airbill div:nth-child(5) select {
  width: 190px !important;
}
.airbill div:nth-child(6) select {
  width: 190px !important;
}
.airbill div:nth-child(7) select {
  width: 160px !important;
}
.housbill{
  width: 42%;
}
.housbill .ragap div:nth-child(2) input{
  width: 164px !important;
}
.housbill .ragap div:nth-child(3) input{
  width: 94px !important;
}
.housbill .ragap div:nth-child(4) input{
  width: 80px !important;
}
.jqx-grid-cell-left-align{
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  margin-top: 14px;
}
.bookingstatus{
  margin-top: 13px;
}
.jqx-widget-header{
  font-size: clamp(1rem, 2.5vw, 1.2rem) !important;
}
.jqx-checkbox-default{
  margin: 4px 3px 0px 3px;
}
.bookinggrid{
  height: 70dvh !important;
}
.SecondDiv{
  max-height: 70dvh;
}
.SecondDiv h3{
  font-size: 18px;
}
.racomment {
  h3 {
    font-size: 16px;
  }
  p{
    font-size: 17px;
  }
  span{
    font-size: 14px;
  }
}
.girddtals_header h3{
  font-size: 26px;
  span{
    font-size: 16px;
  }
  svg{
    font-size: 18px;
  }
}
.actbtn {
  Button {
    height: 35px;
    font-size: 16px;
    border-radius: 5px;
  }
}
.rabord {
  .firstbrd, .lastbrd, .middlebrd {
      span{
        font-size: 20px !important;
      }
      p{
        font-size: 17px;
      }
  }
}
.opt_truck {
  h4{
    font-size: 17px;
  }
  span {
      font-size: 16px;
  }
  a{
    font-size: 15px;
  }
}

.opt_truck {
  .offer_reecv {
    width: 120px;
    padding: 5px 10px;

    span{
      font-size: 15px;
    }
  }
  .offer_rqst{
    width: 120px;
    padding: 5px 10px;
    span{
      font-size: 15px;
    }
  }
}
.requested, .booked, .offered{
  width: 140px;
  margin: 0;
  margin-top: 4px !important;
  font-size: 16px !important;
}
.activity {
  span {
    font-size: 16px;
  }
  button{
    font-size: 16px;
  }
}

  }