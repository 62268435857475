.uploadbox{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    }
    .uploadfil{
      width: 31.7%;
      margin-bottom: 0;
      background: #F4F7FF;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
    }
    .fileimg{
        width: 30px;
        margin-right: 10px;
      }
      .detailsbtn{
        align-self: center;
      }
      .detailsbtn button{
        border: none;
    background: none;
    color: #6B748B;
    font-size: 0.9rem;
    padding: 0 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
      }

      .backdetails{
        border: none;
        background: none;
        margin-bottom: 15px;
        font-size: 15px;
        color: #000;
        font-family: "Roboto", sans-serif ;
          font-weight: 500;
          display: inline-block;
      }

      .viewbook{

        h4{
          font-size: 15px;
          font-family: "Roboto", sans-serif ;
          font-weight: 500;
          margin-bottom: 0;
          span.raopen{
            color: #45A73A;
            margin-left: 10px;
          }
        }
        p{
          margin-bottom: 0;
          color: #7E7E7E;
          font-size: 14px;
        }
      }
      
      .gird_details{
        padding: 10px 25px;
        .accordion-item{
          background: #F3F3F3;
          border: 1px solid #e3e3e3;
          margin-bottom: 10px;
          border-radius: 5px;
        }
        .accordion-button.collapsed{
          border-radius: 5px;
        }
        .accordion-button{
          background: #447BCE !important;
          color: #fff !important;
          border-radius: 5px 5px 0 0;
          font-size: 16px;
          font-family: "Roboto", sans-serif ;
          font-weight: 500;
          padding: 12px 20px !important;
        }
        .accordion-body{

          div{
            display: flex;
          }

          ul{
            padding-left: 0;
            width: 50%;
            margin-bottom: 0;

            li{
              line-height: 30px;
              padding: 6px 15px;
              span:first-of-type{
                font-size: 14px;
                font-family: "Roboto", sans-serif ;
                font-weight: 500;
                color: #131313;
                display: inline-block;
                width: 160px;
              }
              span:last-of-type{
                margin-left: 15px;

              }

            }
            li:nth-child(even){
              background: #fff;
            }

            
            
          }

          
        }
      }

      .details_table{

        table tr th{
          font-size: 14px;
          font-family: "Roboto", sans-serif ;
          font-weight: 500;
          background: #E1E1E1;
        }
      }

      .raimage_file{
      width: 100px;
      height: 100px;
      background: #ccc;
      border-radius: 5px;
      border: 4px solid #fff;
      box-shadow: 2px 2px 2px #ccc;
      }
      .raimage_file img{
      width: 100%;
      }
      .ragap{
      gap: 20px;
      }
      .arch_btn{
      background: none;
      border: none;
      height: 40px;
      color: #bdbdbd;
      }
      .truck_info{
      ul{
      padding: 0;
      }
      }
      .showItem{
      
      }
      .eachItem{
        display: flex;
        justify-content: space-between;
        background: #F9F9F9;
        border: 1px solid #E8E8E8;
        border-radius: 5px;
        padding: 8px 10px;
        margin-bottom: 10px;
        align-items: center;

        h3{
          margin-bottom: 0;
          font-size: 15px;
          color: #2B2F36;
          font-weight: 400;

          svg{
            font-size: 16px;
            color: #79acff;
            margin-right: 10px;
          }
        }
      }

      .request_email{
        button{
          background: none;
          border: none;
          color: #b9b9b9;

        }
      }
      .add_email{
        margin-bottom: 15px;
        position: relative;
        input{
          background: #F9F9F9;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    padding: 8px 10px;
    width: 100%;
        }
        button{
          position: absolute;
          right: 5px;
          top: 4px;
          background: #2866C2;
          color: #fff;
          border: none;
          border-radius: 4px;
          font-size: 14px;
          padding: 7px 20px;
        }
      }

      
      