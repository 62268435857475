.loader-container{
    /* width: 100%;
    position: relative; */
    width: 100%;
    position: absolute;
    height: 64vh;
    background: #f2f4ffba;
    z-index: 999;
    top: 84px;
}
.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: absolute;
    animation: rotate 1s linear infinite;
    display: block;
    left: 50%;
    top: 40%;
    z-index: 999;
  }
  .loader::before , .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #ccc;
    animation: prixClipFix 2s linear infinite ;
  }
  .loader::after{
    transform: rotate3d(90, 90, 0, 180deg );
    border-color: #2866C2;
  }

  @keyframes rotate {
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  }


.schedule{
    display: flex;
}
  body.sidebar-icon-only .schedule nav.sidebar{
    margin-left: 0;
  }
.mvownload{
    width: 240px;
}
.exportbtn{
    background: #FFFFFF;
    border-radius: 4px;
    border: none;
    color: #2B2F36;
    padding: 8px 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 3px 6px #DDE6FB;

    span{
        display: inline;
        font-size: 14px;
        margin-left: 10px;
    }
}
.exportbtn.active{
    background: #4A89E8;
    color: #fff;
}


.search .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.search .has-search .form-control {
    padding-left: 2.375rem;
}

.lcontent{
    position: relative;
}
.shipment_grid{
    position: absolute;
    top: 62px;
    right: 10px;
}

.shipment_grid button{
    border: none; 
    background:none; 
    color: #6B748B;
    font-size: 0.9rem;
    padding:0 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap:10px;
}

.lcontent .exp-btn{
    position: absolute;
    top: 18px;
    right: 10px;
}
.lcontent .exp-btn button{
    border: none; 
    background:none; 
    color: #6B748B;
    font-size: clamp(0.9rem, 2.5vw, 0.9rem);
    padding:0 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap:10px;
}
.addtruck{
    background: #2866C2;
    border-radius: 5px;
    border: none;
    color: #fff;
    padding: 8px 20px;
}

.schedule_calender .day{
    width: 65px;
    padding: 0 0;
    border: none;
    border-radius: 10px;
    margin: 0;
    cursor: pointer;

}
.schedule_calender .day.active{
    border: 2px solid #589bff;
    background: #f2f4ff;
    border-radius: 6px;
}

.schedule_calender .day-name{
    font-weight: 500;
    color: #2866C2;
    font-size: 13px;
    margin: 0;
  }
  
  .schedule_calender .date{
    font-size: 13px;
    color: #454545;
    font-weight: 400;
    margin: 0;
    font-family: "Roboto", sans-serif;
  }
  .schedule_calender .btnleft,   .schedule_calender .btnright{
    padding: 0 10px;
    border-radius: 0;
    box-shadow: none;
    border: none;
    color: #4a89e8;
    margin: 0;
    background: none;
  }

  .schedule_calender .current-date, .schedule_calender .current-date.active {
    border: 1px solid #1654B0;
    background: #1654B0;
    color: #fff;
    padding: 5px 0;
    border-radius: 4px;
    border: 2px solid transparent;

    .day-name{
        color: #fff;
        line-height: 14px;
    }
    .date{
        color: #fff;
        line-height: 14px;
    }
}
.clendericon{
    cursor: pointer;
    background: #ffb300;
    color: #fff;
    padding: 8px 15px;
    border-radius: 6px;

    svg{
        font-size: 20px;
    }
}

.search{
    width: 220px;
    .jiMOeR, .eUeawN{
        height: auto;
    }

    div.wrapper{
        z-index: 999 !important;
        border-radius: 6px !important;
        font-size: 14px !important;
        box-shadow: rgb(211 228 255) 0px 4px 7px 0px !important;
        border: 1px solid rgb(229 237 252) !important;
        .dYrfwz, .bNTaAG{
            min-height: 38px;
        }
        ul {

            li{
                cursor: pointer;
                color: #707070;
            }
            .selected{
                font-size: 14px !important;
                color: #000 !important;
                font-weight: 500;
            }
        }

        input{
            text-transform: capitalize;
        }
        

    }
    .ffXrxx .selected{
        font-size: 14px !important;
        color: #707070 !important;
        cursor: pointer;
    }

}


.addfrom{
    z-index: 9999;
    .modal-content{
        background: #F2F5FC;
    }
   .modal-header{
    padding: 15px 30px;
    border-bottom: none;
    display: block;
    position: relative;

    p{
        margin-bottom: 0;
    }

    .btn-close{
        position: absolute;
        right: 12px;
        top: 12px;
        z-index: 99;
    }

    .modal-title{
        font-size: 18px;
        color: #2866C2;
    }
   }
    .modal-body{
        padding-bottom: 0 !important;
        padding: 0 30px !important;
.tempra{
    line-height: 30px;
}
        .form-label{
            font-size: 14px;
            color: #2B2F36;
            font-family:'Segoe UI Semibold';
            position: relative;
            width: 100%;
        }
        .form-check{
            display: inline-block;
            margin-right: 1rem;
            background: #fff;
            padding: 5px 20px 5px 40px;
            border-radius: 20px;
            box-shadow: 1px 5px 6px #DDE6FB;

            label{
                margin-right: 0;
                display: flex;
            }
        }
        .form-check-inline {
            display: inline-block;
            margin-right: 1rem;
            background: #fff;
            padding: 5px 20px 5px 40px;
            border-radius: 20px;
            box-shadow: 1px 5px 6px #DDE6FB;
            margin-bottom: 0.5rem;

            label{
                margin-right: 0;
                font-size: 13px;
            }
        }
        .form-control{
            border: none;
            box-shadow: 1px 5px 6px #DDE6FB;
            height: 40px;
            font-size: 14px;
        }
        .form-select{
            border: none;
            box-shadow: 1px 5px 6px #DDE6FB;
            height: 40px;
            font-size: 14px;

            .add-address-option{
                color: #0d6efd;font-weight: 500;
            }
        }
               
        .form-control::placeholder{
            font-size: 14px;
            color: #707070;
        }
        hr{
            color: #8ba8ed;
        }
    }
    .modal-footer{
        border-top: none;
        padding: 20px 30px;
        button{
            background: #1654B0 !important;
            width: 100%;
            padding: 10px 0;
            margin: 0;
        }
    }
}

.autosugg{
    position: relative;
    input{
        border: none;
        box-shadow: 1px 5px 6px #DDE6FB;
        height: 40px;
        display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    appearance: none;
    background-color:#fff;
    border-radius: 6px;
    font-family:'Segoe UI';
    }
    input:focus-visible{
        outline: none !important;
    }
        Button{
        position: absolute;
        right: 0;
        top: 2px;
        background: none !important;
        border: none !important;
        color: #0d6efd;
        font-size: 14px;
        font-weight: 500;
        }
        Button:hover{
            color: #0d6efd;
        }
        .react-autosuggest__suggestions-container{
            width: 100%;
            background: #fff;
            box-shadow: 1px 5px 6px #DDE6FB;
            margin-top: 5px;
            border-radius: 6px;
            position: absolute;
            z-index: 999;
            max-height: 200px;
            overflow: auto;

            ul{
                padding-left: 0;

                li{
                    padding: 8px 10px;
                    font-size: 14px;
                    font-family:'Segoe UI Semibold';
                    cursor: pointer;
                }
            }

        }
}

.maindiv {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-bottom: 30px; 
  }
  
  .trashdonload {
    width: 100%;
    cursor: pointer;
  }
  .SecondDiv {
    width: 30%;
    background-color: #FFFFFF;
    box-shadow: 0px 6px 8px #D6E4F8;
    border-radius: 10px;
    padding: 15px;
    position: relative;
    max-height: 134.8vh;
    overflow: auto;
  }
  .SecondDiv h3{
    font-size: 14px;
    color: #1F2123;
    font-family: "Roboto", sans-serif ;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .jqx-listbox-container .jqx-checkbox{
top:8px !important;
  }

  .bookinggrid{
    box-shadow: 0px 6px 8px #D6E4F8;
    border-radius: 10px;
    padding: 0 15px;
    min-height: 585px;
    box-sizing: border-box;
    .jqx-filter-input{
        margin: 6px !important;
        height: 32px !important;
        border-radius: 4px;
    }
    .jqx-grid-cell-filter-row{
        .jqx-dropdownlist{
        margin: 6px !important;
        height: 30px !important;
        border-radius: 4px;
        }
    }
    
    .jqx-datetimeinput {
        margin: 6px !important;
        height: 30px !important;
        border-radius: 4px;
    }
    .jqx-checkbox{
        margin-top: -7px !important;
    }
    .jqx-checkbox-default > div{
        width: 12px !important;
        height: 12px !important;
        .jqx-checkbox-check-checked{
            width: 12px !important;
        height: 12px !important;
        }
    }
    .firstcl{
        font-weight: 500;
    }
    .jqx-grid-pager > div {
        top: 6px !important;
    }


  }
  .close{
    position: absolute;
    right: 5px;
    top: 5px;
    background: none;
    border: none;
    svg{
        font-size: 18px;
        color: #1E1E1E;
    }

  }
  .girddtals_header{
    border-bottom: 1px solid #ededed;
    padding-bottom: 10px;
h3{
    color: #2B2F36;
    font-size: 20px;
    font-family: "Roboto", sans-serif ;
    font-weight: 500;
    span{
        font-size: 14px;
    color: #a6a6a6;
    font-weight: 400;

        svg{
            color: #20A749;
            font-size: 16px;
        }
        .svg-inline--fa.fa-circle {
            color: #ccc;
            font-size: 14px;
        }
    }
}
  }
  .actbtn {
    Button{
        background: #F5F5F5;
        border: 0;
        color: #6B6B6B;
        font-weight: 500;
        height: 28px;
        font-size: 13px;
        margin-right: 10px;
        line-height: 1;
        border-radius: 2px;
    }
    Button:hover{
        background: #f3f3f3;
    }
    Button:active{
        background: #f3f3f3;
    }
    .btn.show{
        background-color: #efefef;
    }
    .dropdown{

        .dropdown-menu{
            background: #fff ;
            border: 0;
            color: #6B6B6B;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            border-radius: 6px;
            font-family: "Roboto", sans-serif ;
            box-shadow: 0 5px 10px #e1e1e1;

            .dropdown-item{
                color: #807f7f;
            }

            .dropdown-item:hover{
                font-weight: 500;
                color: #515151;
            }
        }
   
    }
  
  }

  .rabord{
    padding: 15px 0;
    align-items: center;
    p{
        margin-bottom: 0;
        font-family: "Roboto", sans-serif ;
    }
    span{
        display: block;
        text-align: center;
        font-family: "Roboto", sans-serif ;
    }
    .firstbrd, .lastbrd{
        position: relative;
        p{
            font-size: 13px;
            color: #A1A1A1;
        }
        span{
            font-size: 15px;
            color: #2866C2;
            font-weight: 500;
        }
    }
    .middlebrd{
        width: 55%;
        position: relative;
        text-align: center;
        p{
            font-size: 14px;
            color: #2B2F36;
            font-weight: 500;

            img{
                position: relative;
            }
        }
        
    }
    .firstbrd::after{
        content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background: #E8E8E8;
    right: -30px;
    top: 36%;
    z-index: 9;
    }
    .lastbrd::before{
        content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background: #E8E8E8;
    left: -30px;
    top: 36%;
    z-index: 9;
    }
    .middlebrd::before{
        content: "";
        border-top: 2px dashed #d5d5d5;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;

    }
  }
  .raplas{
    background: none;
    border: none;
    line-height: 32px;
  }
  .rightdrop{
    .dropdown{
        button{
            padding: 0 10px;
    color: #000000;
    background: none;
        }
        .btn.show{
            background: #fff;
        }
        .dropdown-menu{
            background: #fff ;
            border: 0;
            color: #6B6B6B;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            border-radius: 6px;
            font-family: "Roboto", sans-serif ;
            box-shadow: 0 5px 10px #e1e1e1;
    
            .dropdown-item{
                color: #807f7f;
            }

            .dropdown-item:hover{
                font-weight: 500;
                color: #515151;
            }
        }

    } 
  }

 
 .raslider{
    margin-bottom: 10px;
 }
  .raslider .slideimg{
    position: relative;
    background-color: #F6F6F6;
    border: 1px solid #E9E9E9;
    padding: 12px 10px; 
    /* height: 48px; */
    display: flex !important;
    border-radius: 5px;

  }
.raslider .slide_dltbtn{
    position: absolute;
    right: 5px;
    top: -6px;
    box-shadow: none;
    border: none;
    padding: 0;
    background: none;

    svg{
        font-size: 12px;
        color: #979797;
    }
}
.raslider .slide_dwnldbtn{
    position: absolute;
    right: 20px;
    top: -6px;
    box-shadow: none;
    border: none;
    padding: 0;
    background: none;

    svg{
        font-size: 12px;
        color: #979797;
    }
}
figure.image img{
    width: 100px;
}
.raslider .slick-prev{
    left: -11px;
    z-index: 99;
}
.raslider .slick-next{
    right: -9px;
    z-index: 99;
}
.raslider .slick-next:before, .raslider .slick-prev:before{
color: #ccc !important;
}

.raslider .slick-list {margin: 0 -5px;}
.raslider .slick-slide>div {padding: 0 5px;}
.rafilename{
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 24px;
}
.raslider .fileimg{
    margin-right: 5px !important;
}
.imgmodal{
    .modal-header{
        padding: 0;
        border: none;
    }
    .btn-close{
        position: absolute;
    right: -30px;
    top: -20px;
    }
}

.activity {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    span{
        font-size: 13px;
        font-family: "Roboto", sans-serif ;
        font-weight: 400;
        color: #a2a2a2;
    }
    button{
        background: #F1F1F1;
        color: #69798f;
        font-size: 13px;
        font-family: "Roboto", sans-serif ;
        font-weight: 400;
        border-radius: 3px;
        padding: 3px 9px;
        text-decoration: none;
    }
    button:hover{
        background: #505F79;
        color: #fff;
    }
    button.active{
        background: #505F79;
        color: #fff;
    }
}

.edithtml{
    width: 90%;
}
.user_com{
    width: 27px; 
    height: 27px;
    background: #6591DE;
    border-radius: 50px;
    color: #fff;
    line-height: 27px;
    display: block;
    text-align: center;
    margin-right: 10px;
    font-size: 13px;
}
.opttrauck{
    .accordion-item{
    border: 1px solid #e3e3e3 !important;
    margin-bottom: 10px; 
    border-radius: 5px;
    .accordion-button{
        font-size: 14px !important;
    color: #2B2F36 !important;
    font-family: "Roboto", sans-serif ;
    font-weight: 400;
    }
    }
}

.truck_info{
    padding-left: 0;
    margin-bottom: 0;

    li{
        color: #979797;
        font-size: 13px;
        font-family: "Roboto", sans-serif ;
        font-weight: 400;
        line-height: 28px;

        span:first-of-type{
            font-family: "Roboto", sans-serif ;
            color: #2B2F36;
            font-weight: 400;
            width: 40%;
            margin-left: 4px;
            display: inline-block;
            font-size: 14px;
        }
    }
    h6{
        margin-bottom: 5px;
        color: #2B2F36;
        font-size: 15px;
    }
}
.left_rqst{
    label{
        margin: 0;
        margin-left: 10px;
    }
}
.comntbox{
    margin: 15px 0 0 0;
}
.nonslide {
    display: flex;
    width: 100%;
    gap: 10px;
}
.nonslide .slideimg{
    width: 48%;
}
.racomment{
    padding: 10px 0;
    gap: 10px;
    border-bottom: 1px solid #e5e5e5;

    .coment_img{
        width: 30px;
        height: 30px;
        background: #ccc;
        border-radius: 50px;
        object-fit: cover;
        overflow: hidden;
        img{
            width: 100%;
        }
    }

    h3{
        margin-bottom: 0;
        line-height: 24px;
        color: #343434;
        margin-right: 15px;
        font-size: 13px;

    }
    span{
        font-size: 13px;
        color: #afafaf;
    }
    p{
        margin-bottom: 0;
    font-size: 14px;
    color: #797979;
    line-height: 1;
    }
    button{
        padding: 0 10px;
        line-height: 16px;
        svg{
            color: #afafaf;
    font-size: 12px;
        }
    }
}


.staion_grid .jqx-position-relative{
    border: 1px solid #E8E8E8 !important;
    border-radius: 10px;
}
.staion_grid .jqx-position-relative > div{
    padding-left: 10px;
}
.createplan{
    background: #2866C2;
    border-radius: 5px;
    border: none;
    color: #fff;
    padding: 7px 22px;
    margin-bottom: 12px;
    display: inline-block;
  }

  .multiselect .dropdown-container{
    border: none !important;
    box-shadow: 1px 5px 6px #DDE6FB;
    
    height: 40px;
    font-size: 14px;  
  }
  .backbtn{
    background: none;
    border: none;
    position: absolute;
    left: 20px;
    top: 10px;
  }
  .add_adres{
    background: none;
    border: none;
    color: #1654B0;
    font-weight: 500;
    font-family: "Roboto", sans-serif ;
    font-size: 14px;
  }
  .addresses{
    max-height: 56dvh;
    overflow: auto;
  }
  .addres_box{
    background: #F2F5FC;
    border-radius: 6px;
    padding: 15px 15px;
    margin-bottom: 15px;

    ul{
        padding: 0;
        margin: 0;

        h3{
            color: #1654B0;
            font-size: 16px;
            margin-bottom: 8px;
        }

        li{
            line-height: 26px;
            color: #7B7B7B;
            font-size: 13px;
            font-family: "Roboto", sans-serif ;
            font-weight: 400;
            span:first-of-type{
                width: 120px;
                display: inline-block;
                font-size: 13px;
                color: #393939;
            }
            span:last-of-type{
                color: #7B7B7B;
                font-size: 13px;
                margin-left: 10px;
            }
        }
    }
  }
.selectbx{
    border: 1px solid #2866C2;
    position: relative;
    .confrm{
        display: block;
        color: #2866C2;
        font-size: 18px;
        position: absolute;
        right: 10px;
        top: 10px;
    }
}
.confrm {
	display: none;
}
  .opt_truck{
    background: #F8F8F8;
    border: 1px solid #E9E9E9;
    border-radius: 4px;
    padding: 8px 15px;
    margin-bottom: 10px;

    div:first-of-type{
        line-height: 1;
    }

    span{
        font-size: 13px;
        color: #919191;
        font-family: "Roboto", sans-serif;
    }

    h4{
        font-size: 13px;
    color: #414141;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 2px;
    }
    a{
        font-size: 13px;
    color: #2866C2;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    }
    .offer_reecv{
        background: #FCEBCB;
        width: 110px;
        text-align: center;
        padding: 2px 0;
        border-radius: 3px;
        line-height: 22px;
        span{
            color: #FC8E11;
            font-size: 12px;
            font-family: "Roboto", sans-serif;
            font-weight: 500;
        }
    }

    .offer_rqst{
        background: #E8E8E8;
        width: 110px;
        text-align: center;
        padding: 2px 0;
        border-radius: 3px;
        line-height: 22px;
        span{
            color: #4b4b4b;
            font-size: 12px;
            font-family: "Roboto", sans-serif;
            font-weight: 400;
        }
    }

  }

  .offerbg{
    background: #FCEBCB;
    border: 1px solid #FFDE9F;
    border-radius: 4px;
    padding: 10px 15px;
    margin-bottom: 10px;

    div:first-of-type{
        line-height: 1;
    }

    span{
        font-size: 13px;
        color: #9f8046;
        font-family: "Roboto", sans-serif;
    }

    h4{
        font-size: 13px;
    color: #616161;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 2px;
    }
    .received{
        span{
            font-size: 12px;
        color: #FC8E11;
        font-family: "Roboto", sans-serif;
    font-weight: 500;
        }
    }
  }

  .manag_offer{
    font-size: 12px;
    color: #D84343;
    font-family: "Roboto", sans-serif;
    span{
        width: 15px;
    height: 15px;
    border-radius: 2px;
    background: #D84343;
    color: #fff;
    display: inline-block;
    text-align: center;
    font-size: 11px;
    line-height: 15px;
    margin-left: 3px;
    }
  }

.handler_name{
    position: relative;
    h2{
        color: #1654B0;
        font-size: 20px;
    }

    h3{
        color: #2B2F36;
        font-size: 15px;
        margin-bottom: 10px;
    }
}
.brd_rgt{
    border-right: 2px solid #edf2ff;
}

.raedit{
    position: absolute;
    right: 0;
    .btn-link{
        padding: 0 5px;
        color: #2866c2;
        font-size: 13px;
        text-decoration: none;

        svg{
            color: #7ea1ff;
            font-size: 11px;
        }

    }
}
  .handler_info{

     ul{
        padding: 0;
        margin: 0;

        h3{
            color: #2B2F36;
            font-size: 15px;
            margin-bottom: 10px;
        }

        li{
            line-height: 26px;
            color: #7B7B7B;
            font-size: 13px;
            font-family: "Roboto", sans-serif ;
            font-weight: 400;
            span:first-of-type{
                width: 120px;
                display: inline-block;
                font-size: 13px;
                color: #1a1a1a;
            }
            span:last-of-type{
                color: #767676;
                font-size: 13px;
                margin-left: 10px;
            }
        }
    }
  }

  .station_grid{
    border: none;
    margin-left: 10px;
        li{
            .nav-link{
                padding: 10px 18px !important;
                border: none;
                border-bottom: 2px solid #fff;
                background: none !important;
                color: #939393;
                font-size: 15px;
                font-family: "Roboto", sans-serif ;

            }
            .nav-link.active{
                border-bottom: 3px solid #2866C2;
                color: #2866C2;
                font-weight: 500;

            }
        }

       

  }


.frcl .jqx-grid-cell-left-align{
    font-family: "Roboto", sans-serif ;
    color: #2B2F36 !important;
    font-weight: 500 !important;
}
  .moveto{
    position: relative;

    .moveto_btn{
        position: absolute;
        right: 0;
        top: -34px;
        button{
            background: none;
            border: none;
            font-size: 15px;
            color: #616161;
            padding: 0 10px;
            
            img{
                width: 18px;
                margin-right: 4px;
            }
        }
    }
}

.commnt_save{
    margin-left: 38px;
    margin-top: 6px;

    .rasave{
        padding: 3px 15px;
        border-radius: 4px;
        background: #4a89e8 !important;
        border: 1px solid #4a89e8;
    }
    .racancel{
        text-decoration: none;
    color: #505f79;
    font-size: 14px;
    font-weight: 600;
    }
}

.booking_tab{
    border-bottom: none;
    li{
        margin: 0 10px;
        margin-bottom: 2px;
        button.nav-link{
            border: none;
    background: none !important;
    padding: 8px 15px !important;

    span{
        font-size: 14px;
        color: #9E9E9E;
        font-weight: 500;

        .badge{
            color: #fff !important;
                    font-size: 12px;
                    line-height: 14px;
                    border-radius: 5px;
        }
    }
        }
        button.active{
            border-bottom: 3px solid #2866c2 !important;

            span{
                color: #000 !important;

                .badge{
                    color: #fff !important;
                    font-size: 12px;
                }
            }
        }
    }
}
.jqx-checkbox-check-indeterminate{
    width: 12px !important;
    height: 12px !important;
}
.bookingstatus{
    margin-top: 15px;
    padding-left: 25px;
}
.bookingstatus.active::before{
    content: '✔';
    position: absolute;
    left: 5px;
    top: 15px;
    background: #2cbb38;
    color: #fff;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    border-radius: 15px;
}

.bookingstatus.inactive::before{
    content: '';
    position: absolute;
    left: 5px;
    top: 15px;
    background: #D1D1D1;
    width: 16px;
    height: 16px;
    border-radius: 15px;
}

.truck_order{
z-index: 9999;
    .nxtbtn{
        .trnext{
            padding: 2px 20px !important;
    height: 33px;
    font-size: 13px;
    line-height: 1;
        }
    }
    .stepper-right{
        padding: 20px 0;

    }
    .rasteper{
        padding: 40px 0px 40px 0;
        border-radius: 7px 0 0 7px;
    }
    .modal-content {
        border: none;
    }
    .modal-header{
padding: 0 !important;
    }
    .modal-body{
        padding: 0 !important;
    }
    .btn-close {
        z-index: 999;
    }
    table,td{
        font-size: 13px;
        color: #505050  !important;
        font-weight: 500 !important;
    }
    table,th{
        font-weight: 600;
        color: #000;
    }
    h2{
        margin-bottom: 15px;
    }
    .form-check-inline{
        padding: 3px 20px 3px 35px !important;
    }
    .form-control{
        height: 36px !important;
    }
    .dropfile{
        padding: 40px 0;
    }
    .uploadfil{
        width: 48%;
    }
    .rate_box{
        background: #fff;
        border-radius: 5px;
        box-shadow: 0px 5px 7px 1px #D3DBFB;
        padding: 10px;
        margin-bottom: 10px;
        /* ul{
            padding: 0;
            margin-bottom: 0;

            li{
                font-size: 13px;
                line-height: 22px;
                span:first-of-type{
                    color: #898989;

                }
                span:last-of-type{
                    float:right;

                }
            }
        } */
    }
    .rate_box.raselect{
        border: 1.5px solid #2866C2;
        position: relative;

        .rachck{
            position: absolute;
            right: -5px;
            top: -9px;

            svg{
                color: #2866C2;
            }

        }
    }

    .multis{
        min-height: 300px;
        .dropdown-content{
            padding: 10px;
            height: 300px;
            overflow:auto;
        }
    }

    .stepper-right{
        .stepper-frms{
            width: 65%;
        }
        .stepper-frm-info{
            width: 35%;
        }
    }

}

.type_work{
    max-height: 360px;
    overflow-y: auto;
    overflow-x: hidden;
}

.freq_week{
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: start;
    gap:20px;

    li{
        width: 32px;
        height: 32px;
        background: #fff;
        border-radius: 30px;
        text-align: center;
        line-height: 32px;
        font-size: 13px;
        font-weight: 500;
        box-shadow: 1px 5px 6px #DDE6FB;
        cursor: pointer;  
    }
    .selected{
        background: #2866C2;
        color: #fff;
    }
}



.plans {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 8px 10px 0px #d8dfeb;
    box-shadow: 0px 8px 10px 0px #d8dfeb;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .plans .plan input[type="radio"] {
    position: absolute;
    opacity: 0;
  }
  
  .plans .plan {
    cursor: pointer;
    width: 48.5%;
  }
  
  .plans .plan .plan-content {
    padding: 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #ffffffde;
    border: 2px solid #ffffffde;
    border-radius: 6px;
    -webkit-transition: -webkit-box-shadow 0.4s;
    transition: -webkit-box-shadow 0.4s;
    -o-transition: box-shadow 0.4s;
    transition: box-shadow 0.4s;
    transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
    position: relative;

    ul{
        padding: 0;
        margin-bottom: 0;

        li{
            font-size: 13px;
            line-height: 22px;
            span:first-of-type{
                color: #898989;

            }
            span:last-of-type{
                float:right;

            }
        }
    }
  }
  
  .plans .plan .plan-content img {
    margin-right: 30px;
    height: 72px;
  }
  
  .plans .plan .plan-details span {
    margin-bottom: 10px;
    display: block;
    font-size: 20px;
    line-height: 24px;
    color: #252f42;
  }
  
  .container .title {
    font-size: 20px;
    font-weight: 500;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    color: #252f42;
    margin-bottom: 20px;
  }
  
  .plans .plan .plan-details p {
    color: #646a79;
    font-size: 14px;
    line-height: 18px;
  }
  
  .plans .plan .plan-content:hover {
    -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
    box-shadow: 0px 3px 5px 0px #e8e8e8;
  }
  
  .plans .plan input[type="radio"]:checked + .plan-content:after {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    background: #216fe0;
    right: 6px;
    top: 4px;
    border-radius: 100%;
    border: 2px solid #fff;
    -webkit-box-shadow: 0px 0px 0px 2px #0066ff;
    box-shadow: 0px 0px 0px 2px #0066ff;
  }
  
  .plans .plan input[type="radio"]:checked + .plan-content {
    border: 2px solid #216ee0;
    background: #eaf1fe;
    -webkit-transition: ease-in 0.3s;
    -o-transition: ease-in 0.3s;
    transition: ease-in 0.3s;
  }
  
  .requested{
    width: 108px;
    background: #ececec;
    text-align: center;
    padding: 8px;
    margin: 9px 0;
    color: #676767 !important;
    font-size: 12px;
    font-weight: 500;
    font-family: "Roboto", sans-serif !important;
    border-radius: 0px;

  }
  .offered{
    width: 108px;
    background: #FCEBCB;
    text-align: center;
    padding: 8px;
    margin: 9px 0;
    color: #FC8E11 !important;
    font-size: 12px;
    font-weight: 500;
    font-family: "Roboto", sans-serif !important;
    border-radius: 0px;
  }
  .booked{
    width: 108px;
    background: #DCFFEB;
    text-align: center;
    padding: 5px;
    color: #4BDB85 !important;
    font-size: 12px;
    font-weight: 500;
    font-family: "Roboto", sans-serif !important;
    border-radius: 0px;
  }
  

  .jqx-filter-input{
    margin: 6px !important;
    height: 32px !important;
    border-radius: 2px;
}
/* .jqx-checkbox{
    margin-top: -7px !important;
} */
.jqx-checkbox-default > div{
    width: 12px !important;
    height: 12px !important;
    .jqx-checkbox-check-checked{
        width: 12px !important;
    height: 12px !important;
    }
}
.jqx-grid-cell-filter-row{
    .jqx-item{
        top: 7px !important;
    }
}

.nestedtabs {
    .jqx-checkbox{
        margin-top: -7px !important;
    }
}

.book_sdl{
    z-index: 9999;
}
.staion_add, .typemodal, .raintrac, .imgmodal {
    z-index: 99999;
}
.modal-backdrop{
    --bs-backdrop-zindex: 9999;
}

/* .bookinggrid .jqx-grid-cell{
    background: transparent;
} */
.staion_add > .modal-dialog{
    width: 435px;
}
.rastatus{
    display: flex;
    align-items: center;

    span{
        width: 70px;
    }

    .form-select{
        width: 90px;
    }
}

.userstting{
    box-shadow: none;
    border: 1px solid #d6d6d6 !important;

    .grid_actions{
        justify-content: center;
    align-items: center;
    gap: 15px;
    }
}

.raad_adrss{
    position: absolute;
    right: 0;
    top: 2px;
    background: none !important;
    border: none !important;
    color: #0d6efd;
    font-size: 13px !important;
    font-weight: 500;
    padding: 0;
}
.raad_adrss:hover{
    color: #0d6efd;
}

.set_datetime, .set_dock{
    margin-top: 13px;
}
.set_booking{
    h2{
        font-size: 1.4rem;
        text-align: center;
        width: 100%;
        color: #2866c2;
        font-weight: 500;
    }
    .set_midle{
        width: 60%;
        margin: 0 auto;
    }
    .form-control{
        background: #f6f6f6;
    }
    button{
        height: 38px;
    }
}

.rate_search{
    .form-label{
        margin-bottom: 0;
        margin-right: 10px;
    }
    .multi-select{
        width: 150px;
        border-bottom: 1px solid #ccc;
        padding: 0;
    }
    .dropdown-heading{
        height: 26px;
    }

}

/* ++++++++++++++++++ ULD Control +++++++++++++++++++++++ */

.adduld{
    background: #2866C2;
    color: #fff !important;
    padding: 2px 15px;
    margin-right: 10px;
    font-size: 14px !important;
    border: 1px solid #2866C2;
    .fa-plus{
        margin-right: 3px;
    }
.fa-chevron-down{
    margin-left: 5px;
    font-size: 13px;
}
}
.adduld.btn.show{
    background: #2866C2;
    border: 1px solid #2866C2;
}
.adduld:hover{
    background: #2866C2;
    border: 1px solid #2866C2;
}
.action {
    background: #FFE4A4;
    padding: 2px 15px;
    margin-right: 10px;
    font-size: 14px !important;
    border: 1px solid #FFE4A4;

    .fa-chevron-down{
        margin-left: 5px;
        font-size: 13px;
        color: #5b5b5b;
    }
    .fa-gear{
        color: #5b5b5b;
        margin-right: 3px;
    }

}
.acmvt{
    .fa-chevron-down{
        margin-left: 22px;
    }
}
.action:hover{
    background: #FFE4A4;
    border: 1px solid #FFE4A4;
}

.action.btn.show{
    background: #FFE4A4;
    border: 1px solid #FFE4A4;
}

.condition {
    background: #E7FAEF;
    padding: 2px 15px;
    border: none;
    box-shadow: none;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 14px !important;

    .fa-circle-check{
        color: #5b5b5b;
        margin-right: 3px;
    }
}
.selected{
    background: none;
    padding: 2px 15px;
    border: none;
    box-shadow: none;
    font-size: 12px !important;
    align-self: center;
    font-weight: 500;
    color: #8b8b8b;

    span.badge{
        background-color: #98C1FF !important;
        font-size: 12px;
        line-height: 22px;
        width: 26px;
        height: 22px;
        padding: 0;
        border-radius: 5px;
    }
}
.ulddown{
    position: absolute;
    right: 8px;
    top: 15px;

    button{
        background: none;
        border: none;
        font-size: 15px;
        color: #616161;
        padding: 0 10px;
    }
}



.rabadge{
    background-color: #98C1FF !important;
}

.rounded .dropdown-menu{
border: none;
box-shadow: 0px 5px 10px 2px #D7E2F9;
padding-top: 0;
min-width: 125px;
.dropdown-item{
    font-size: 14px;
    border-bottom: 1px solid #E3E3E3;
    padding: 7px 15px;
}
.dropdown-item:last-child{
    border-bottom: none;
}
}

.transfer {
    background: #FFE0C8;
    padding: 2px 15px;
    border: none;
    box-shadow: none;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 14px !important;

    .fa-money-bill-transfer{
        color: #5b5b5b;
        margin-right: 3px;
    }
}

.receive {
    background: #E9E9E9 ;
    padding: 2px 15px;
    border: none;
    box-shadow: none;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 14px !important;

    .fa-receipt{
        color: #5b5b5b;
        margin-right: 3px;
    }
}
.inop{
    gap: 12px;
    border-top: 1px solid #BED8FF;
    padding-top: 20px;

}
.serv_width > .inbox {
    width: 31.0%;
}


.damage_w{
    gap:10px;
}
.damage_w > div:first-child{
    flex: 1 1 0;
    width: 0;
}
.opara_w > .inbox{
    width: 31.0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.inbox{
    background: #F9F9F9;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 5px 8px #E6EEFF;
    padding: 8px 15px;
    border-radius: 5px;

    .css-1nmdiq5-menu > div{
        max-height: 160px !important;
    }
    .css-1jqq78o-placeholder{
        font-size: 12px;
    }
    span{
        font-size: 14px;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        display: inline-block;
        margin-right: 15px;
    }
    img{
        width: 15px;
        cursor: pointer;
    }

    .form-select{
        box-shadow: none !important;
        height: 31px !important;
        font-size: 13px !important;
        width: 100px;
        color: #747474;
        border-radius: 0;
    }
    .form-control{
        box-shadow: none !important;
        height: 31px !important;
        font-size: 13px !important;
        width: 190px;
    }
    .dis_des{
        width: 360px;
    }
    input::placeholder{
        color: #b1b1b1 !important;
        font-size: 13px;
    }
 
    .upfile{
        font-size: 12px;
    width: 90px;
    padding: 5px;
    border-radius: 4px;
    margin-right: 10px;
    border-color: #B7D4FF;
    color: #5E8DDE;
    }
    .upfile:hover{
        background: #5E8DDE;
        color: #fff;
    }
}


.in_oparation > .modal-dialog{
    --bs-modal-width:700px;
}
.in_oparation .modal-content{
    height: 450px;
}
.in_oparation .modal-header{
    padding-bottom: 0;
}
.in_oparation .modal-header{
    p{
        font-size: 14px;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        color: #878787;
        margin-bottom: 15px;
    }
}
.in_oparation .modal-body{
    max-height: 295px;
    overflow: auto;
}

.addstock{
    background: #fff;
    box-shadow: 0 5px 10px #d6e4f8;
    margin-bottom: 5px !important;
    padding: 15px;
    width: 900px;
    margin: 0 auto;
    border-radius: 10px;
    /* height: 650px; */


    label{
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        color: #090909;
        margin-bottom: 5px;
        position: relative;
        width: 100%;

        span{
            color: #F33030;
        }
    }

    .form-select{
        width: 150px;
    margin-right: 10px;
    border-radius: 3px;
    height: 35px;
    font-size: 14px;
    line-height: 1;
    border: 1px solid #E8E8E8;
    background-color: #fff;
    }
    .form-control{

        width: 150px;
    margin-right: 10px;
    border-radius: 3px;
    height: 35px;
    font-size: 14px;
    line-height: 1;
    background: #fff;
    border: 1px solid #E8E8E8;

    }
}

.autoslct {
    width: 150px;
    margin-right: 10px;
    min-height: 35px;
    font-size: 13px;
    .css-13cymwt-control{
        min-height: 35px;
        border-color: #E8E8E8;
    }
    .css-13cymwt-control:hover{
        border-color: #E8E8E8;
    }
    .css-16xfy0z-control{
        min-height: 36px;
    }
}

.adds_top{
    h3{
        font-size: 17px;
        font-weight: 500;
        font-family: "Roboto", sans-serif;
        color: #000;
    }
    button{
        font-size: 14px;
    padding: 5px 10px;
    border-radius: 4px;
    border-color: #5E8DDE;
    color: #5E8DDE;

    .fa-file-export{
        color: #5E8DDE;
        margin-right: 5px;
    }
    }

    button:hover{
        background: #5E8DDE !important;
        color: #fff;
        border-color: #5E8DDE;

        .fa-file-export{
            color: #fff;
        }
    }
}

.sl_status{
    margin-bottom: 5px;
    label{
        display: block;
    }
    .form-check-label{
        margin-bottom: 0;
        font-size: 13px;
        color: #4c4c4c;

    }
    .form-check {
        display: inline-block;
        margin-right: 1rem;
        background: #f7f7f7;
        padding: 6px 10px 6px 40px;
        border-radius: 20px;
    }
    .form-check-input{
        margin-top: 2px;
    }
}

.alltype{
    align-self: end;
    button{
        font-size: 13px;
    padding: 5px 10px;
    border-radius: 4px;
    color: #898989;
   font-weight: 400;
   border: 1px solid #ccc;
    .fa-gear{
        color: #9f9f9f;
            font-size: 13px;
    }
    }

    button:hover, .btn:active{
        border-color: #d5d5d5;
        color: #646464;
        background: #e5e5e5;

        .fa-gear{
            color: #646464;
        }
        
    }
}

.sub_add{
    background: #F3F3F3;
    padding: 15px;
    border-radius: 5px;

    .form-control{
        background: #fff;
    }
}
.sub_add:first-of-type{
    background: #fff;
    margin-bottom: 0 !important;
    padding-top: 0;
}
/* .sub_add:first-of-type .imag_trash{
    display: none;
} */

.separate{
    border-bottom: 1px dashed #ccc;
}
.stck_middle{
    /* overflow: auto;
    height: 330px; */
}
.stk_add{
background: #6093DF !important;
border: 1px solid #6093DF !important;
border-radius: 5px;
text-transform: uppercase;
font-size: 13px !important;
font-weight: 500;
font-family: "Roboto", sans-serif;

svg{
    margin-right: 5px;
}
}
.stk_cancel{
    background: #FCFCFC !important;
    border: 1px solid #CCCCCC !important;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    width: 115px;
    color: #2B2F36;
    margin-right: 10px;
}
.stk_save{
    background: #2866C2 !important;
    border: 1px solid #2866C2 !important;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    width: 115px;
}
.stk_back{
    color: #000;
}
.asset_type{
    display: flex;
    gap: 5px;
    padding-left: 0;
    margin-top: 5px;

    span{
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        color: #090909;
    }

    label.form-check-label{
        font-size: 14px !important;
        font-family: "Roboto", sans-serif;
        color: #090909 !important;
    }
    .form-check-input{
        margin-left: 5px;
    }
}

.charter_flight{
    width: 1080px;
    margin: auto;
}

.flbg{
    background: #fff;
    box-shadow: 0 5px 10px #d6e4f8;
    padding: 15px;
    border-radius: 10px;
}

.charter_top{
    position: relative;
    h3{
        font-size: 15px;
        font-weight: 500;
        font-family: "Roboto", sans-serif;
        color: #000;
    }

}
.from_width{
    width: 80%;
}
.charter_from{

    label{
        font-size: 13px;
        font-family: "Roboto", sans-serif;
        color: #090909;
        margin-bottom: 5px;
        position: relative;
        width: 100%;

        span{
            color: #F33030;
        }
    }

    .form-select{
    border-radius: 3px;
    height: 36px;
    font-size: 14px;
    line-height: 1;
    background-color: #fff;
    border: 1px solid #E8E8E8;
    width: 80px;
    margin-right: 10px;
    }
    .form-control{
    border-radius: 3px;
    height: 36px;
    font-size: 14px;
    line-height: 1;
    background: #fff;
    border: 1px solid #E8E8E8;
    }
    textarea.form-control{
        height: 50px;
    }
    .autoslct{
        width: 100%;

        div.css-t3ipsp-control{
            min-height: 36px !important;
        }
        div.css-15lsz6c-indicatorContainer {
            padding: 5px !important;
        }
    }
        

}
.au_proses{
    margin-top: 10px;

    .form-control{
        border-radius: 3px;
        min-height: 395px;
        font-size: 14px;
        line-height: 1;
        background: #fff;
        border: 1px solid #E9E9E9;
        }
        .process_btn{
                font-size: 14px !important;
                text-transform: uppercase;
                font-weight: 500;
                background: #6BC0FE !important;
                border: 1px solid #6BC0FE !important;
                width: 180px;
        }
}
.uld_details{
    width: 100%;
    table-layout: fixed;

        th{
        font-size: 13px;
        color: #2866C2;
        font-weight: 500;
        width: 110px;
        border: none;
    }
    th:nth-child(1){
        width: 135px;
    }
    .wdht{
        width: 140px;
    }

  
    td{
        border: none;
        padding: 5px 4px;
        .form-control{
            background: #fff;
            border: 1px solid #E8E8E8;
            height: 32px;
            font-size: 13px;
            border-radius: 2px;
        }
        .form-select{
            background-color: #fff;
            border: 1px solid #E8E8E8;
            font-size: 13px;
            border-radius: 2px;
        }
        input:read-only{
            background: #f5f5f5;
        }
        .chart_switch{
            width: 100%;
        }
        .autoslct{
            width: 100%;
        }
        .css-16xfy0z-control{
            min-height: 32px;
        }
        .css-1fdsijx-ValueContainer{
            padding: 2px 8px 0px 8px;
        }
        .css-1fdsijx-ValueContainer > div.css-1jqq78o-placeholder{
            font-size: 12px;
        }
    }

    .register{
        padding-left: 8px;
    }
    
    td:last-child{
        width: 40px;
        display: inline-block;
        margin-left: 10px;
        img{
            cursor: pointer;
        }
    }
    .switch6-light{
        height: 29px;

        span{
            line-height: 29px;
        }
    }
    .switch6-light label, .switch6-light > span{
        line-height: 29px;
    }

    th:last-child{
        width: 60px;
    }
    .non_reg{
        width: 120px    ;
    }

}


.drop_new{
    border: 1px dashed #b3b3b3;
    padding: 6px 0;
    background: #FBFBFB;
    border-radius: 8px;
    text-align: center;
    width: 300px;

    h3{
        font-size: 14px;
        color: #646464;
        margin-bottom: 5px;
        
        }
    span{
          display: block;
          font-size: 13px;
          color: #AEAEAE;
        }
  }




  .ovgrid{
    /* height: 75dvh !important; */
    overflow: auto;
  }
  .aduld{
    font-size: 14px !important;
                text-transform: uppercase;
                font-weight: 500;
                background: #FFE4A4 !important;
                border: 1px solid #FFE4A4 !important;
                width: 180px;
                color: #2A2A2A;
  }
  .aduld:hover{
    color: #2A2A2A;
  }

  .autometd{
    border-bottom: 2px solid #EBEBEB;
    padding-bottom: 9px;

        li{
            margin-right: 0;
            .nav-link{
                padding: 10px 18px !important;
                border: none;
                background: none !important;
                color: #939393;
                font-size: 14px;
                font-family: "Roboto", sans-serif ;

            }
            .nav-link.active{
                border-bottom: 3px solid #2866C2;
                color: #2866C2;
                font-weight: 500;

            }
        }

       

  }

  .movment_history{
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3{
        margin-bottom: 0;
    }

    a{
        font-size: 13px;
        color: #313131;
        font-weight: 500;
        font-family: "Roboto", sans-serif;
    }
  }
  .zoomse{
    position: absolute;
    right: 0;
    top: -57px;
  }

  .ucmproses{
    .form-control{
        min-height: 293px;
    }
  }

.nswitch{
    width: 130px;
}
.imag_trash{
    align-self: center;
    margin-top: 20px;
    margin-left: 15px;

    img{
        cursor: pointer;
    }
}
.switch6 { 
    margin: 0 auto;
    border:1px solid #c3dafd; 
    border-radius: 30px;
 }
 .switch6.dis { 
    margin: 0 auto;
    border:1px solid #e0e0e0; 
    border-radius: 30px;
 }
 .switch6-light>span,
 .switch-toggle>span {
     color: #000000;
 }
 
 .switch6-light span span,
 .switch6-light label,
 .switch-toggle span span,
 .switch-toggle label {
     font-size: 13px;
     color: #303030;
 }
 
 .switch-toggle a,
 .switch6-light span span {
     display: none;
 }
 
 .switch6-light {
     display: block;
     height: 33px;
     position: relative;
     overflow: visible;
     padding: 0px;
     margin-left: 0px;
     margin-bottom: 0 !important;
 }
 
 .switch6-light a {
     display: block;
     transition: all 0.3s ease-out 0s;
 }
 
 .switch6-light label,
 .switch6-light>span {
     line-height: 30px;
     vertical-align: middle;
 }
 
 .switch6-light label {
     font-weight: 700;
     margin-bottom: px;
     max-width: 100%;
     margin-bottom: 0 !important;
 }
 
 .switch6-light input {
     position: absolute;
     opacity: 0;
     z-index: 5;
 }
 
 .switch6-light input:checked~a {
     right: 0%;
     background: #c3dafd !important;
     border-radius: 30px;
 }

 .switch6-light.dis input:checked~a {
     right: 0%;
     background: #e0e0e0 !important;
     border-radius: 30px;
     border: 1px solid #e0e0e0 !important;
 }
 .switch6-light input:checked~span>span:last-of-type {
     font-weight: 500;
 }
 
 .switch6-light input:checked~span>span:first-of-type {
     font-weight: 400;
 }
 
 .switch6-light>span {
     position: absolute;
     left: -100px;
     width: 100%;
     margin: 0px;
     padding-right: 100px;
     text-align: left;
 }
 
 .switch6-light>span>span:first-of-type {
     font-weight: 500;
 }
 
 .switch6-light>span span {
     position: absolute;
     top: 0px;
     left: 0px;
     z-index: 5;
     display: block;
     width: 50%;
     margin-left: 100px;
     text-align: center;
     cursor: pointer;
     line-height: 33px;
 }
 
 .switch6-light>span span:last-child {
     left: 50%;
 }
 
 .switch6-light a {
     position: absolute;
     right: 50%;
     top: 0px;
     z-index: 4;
     display: block;
     width: 50%;
     height: 100%;
     padding: 0px;
     background: #c3dafd !important;
     border-radius: 30px;
     color: #fff;
     border: 1px solid #dde2ff !important;
 }
 .switch6-light.dis a {
    background: #e0e0e0 !important;
    border-radius: 30px;
    color: #fff;
    border: 1px solid #e0e0e0 !important;
}

.mainTab > .nav-tabs .nav-link.active span{
    font-weight: 600;
    color: #000;
}

.change_type{
    h3{
        font-size: 18px;
        color: #2866C2;
        text-align: center;
    }
    p{
        font-size: 14px;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        color: #878787;
        margin-bottom: 15px;
        text-align: center;
    }
    .asset_midle{
        margin: 30px 0;
        text-align: center;

        .form-check{
            width: 140px;
            text-align: left;
            padding: 8px 40px 8px 60px;
        }
        
    }
}
.chart_switch{
    margin-bottom: -9px;
}
.zoom_up{
    position: absolute;
    right: 0;
    top: -59px;
}

div[id^='horizontalScrollBar']{
.jqx-scrollbar-state-normal{
    background: #efefef !important;
    border: none !important;
    height: 6px !important;
    top: 10px !important;

    .jqx-fill-state-normal{
        height: 5px !important;
        border: none !important;
        background: #bdd7ff !important;
    }
    .jqx-icon-arrow-left, .jqx-icon-arrow-right{
        display: none;
    }
}
}
div[id^='verticalScrollBar']{
    .jqx-scrollbar-state-normal{
        background: #efefef !important;
        border: none !important;
        width: 6px !important;
        left:12px !important;
    
        .jqx-fill-state-normal{
            width: 5px !important;
            border: none !important;
            background: #bdd7ff !important;
        }
        .jqx-icon-arrow-top, .jqx-icon-arrow-bottom{
            display: none;
        }
    }
    }

.trs_height{
    min-height: 33dvh;
    overflow: scroll;
}
.register{
    font-size: 13px;
    color: #2B2F36;

    .fa-circle-check{
        color: #20A749;
    }
}

.non_register{

    font-size: 13px;
    color: #d32323 !important;
    width: 160px;

    .fa-circle-xmark{
        color: #d32323;
    }
}
/* yes or no switch */

.rayes{
    position: relative;
}
.can-toggle {
    position: absolute;
    top: 8px;
  }
  .can-toggle *, .can-toggle *:before, .can-toggle *:after {
    box-sizing: border-box;
  }
  .can-toggle input[type=checkbox] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  .can-toggle input[type=checkbox][disabled] ~ label {
    pointer-events: none;
  }
  .can-toggle input[type=checkbox][disabled] ~ label .can-toggle__switch {
    opacity: 0.4;
  }
  .can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch:before {
    content: attr(data-unchecked);
    left: 0;
  }
  .can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch:after {
    content: attr(data-checked);
  }
  .can-toggle label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
  }
  .can-toggle label .can-toggle__label-text {
    flex: 1;
    padding-left: 32px;
  }
  .can-toggle label .can-toggle__switch {
    position: relative;
  }
  .can-toggle label .can-toggle__switch:before {
    content: attr(data-checked);
    position: absolute;
    top: 0;
    text-transform: uppercase;
    text-align: center;
  }
  .can-toggle label .can-toggle__switch:after {
    content: attr(data-unchecked);
    position: absolute;
    z-index: 5;
    text-transform: uppercase;
    text-align: center;
    background: white;
    transform: translate3d(0, 0, 0);
  }
 
  .can-toggle input[type=checkbox]:focus ~ label .can-toggle__switch, .can-toggle input[type=checkbox]:hover ~ label .can-toggle__switch {
    background-color: #d32323;
  }
   
  .can-toggle input[type=checkbox]:checked ~ label:hover {
    color: #55bc49;
  }
  .can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch {
    background-color: #70c767;
  }
  .can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch:after {
    color: #4fb743;
  }
  .can-toggle input[type=checkbox]:checked:focus ~ label .can-toggle__switch, .can-toggle input[type=checkbox]:checked:hover ~ label .can-toggle__switch {
    background-color: #5fc054;
  }
  .can-toggle input[type=checkbox]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type=checkbox]:checked:hover ~ label .can-toggle__switch:after {
    color: #47a43d;
  }
  .can-toggle label .can-toggle__label-text {
    flex: 1;
  }
  .can-toggle label .can-toggle__switch {
    transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
    background: #d32323;
  }
  .can-toggle label .can-toggle__switch:before {
    color: rgba(255, 255, 255, 0.5);
  }
  .can-toggle label .can-toggle__switch:after {
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    color: #d32323;
  }
 
  .can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch:after {
    transform: translate3d(35px, 0, 0);
  }
 
  .can-toggle label {
    font-size: 14px;
  }
  .can-toggle label .can-toggle__switch {
    height: 26px;
    flex: 0 0 81px;
    border-radius: 15px;
  }
  .can-toggle label .can-toggle__switch:before {
    left: 37px;
    font-size: 11px;
    line-height: 26px;
    width: 47px;
    padding: 0 6px;
  }
  .can-toggle label .can-toggle__switch:after {
    top: 2px;
    left: 2px;
    border-radius: 25px;
    width: 42px;
    line-height: 22px;
    font-size: 11px;
  }
  
 
  .can-toggle.can-toggle--size-small input[type=checkbox]:checked ~ label .can-toggle__switch:after {
    transform: translate3d(44px, 0, 0);
  }
 
  .can-toggle.can-toggle--size-small label {
    font-size: 13px;
  }
  .can-toggle.can-toggle--size-small label .can-toggle__switch {
    height: 28px;
    flex: 0 0 90px;
    border-radius: 2px;
  }
  .can-toggle.can-toggle--size-small label .can-toggle__switch:before {
    left: 45px;
    font-size: 10px;
    line-height: 28px;
    width: 45px;
    padding: 0 12px;
  }
  .can-toggle.can-toggle--size-small label .can-toggle__switch:after {
    top: 1px;
    left: 1px;
    border-radius: 1px;
    width: 44px;
    line-height: 26px;
    font-size: 10px;
  }
  .can-toggle.can-toggle--size-small label .can-toggle__switch:hover:after {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  }

  /* yes or no switch */

  .css-1xc3v61-indicatorContainer{
    padding: 7px !important;
  }
  .css-15lsz6c-indicatorContainer{
    padding: 7px !important;
  }

  .css-t3ipsp-control{
    box-shadow:none !important;
    min-height: 35px !important;
    border-color: #e8e8e8 !important;
  }
  .css-t3ipsp-control:hover {
    border-color: #e8e8e8 !important;
}
.css-1n6sfyn-MenuList{
    max-height: 200px !important;
}
.are_bottom{
    gap:10px;
    button.btn-secondary{
        background: #6c757d !important;
    }
}
.ck_condition{
    .form-check{
        width: 30%;
        padding: 8px 40px 8px 60px;
    }
}

.upload_tmpl{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    button{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 180px;
        padding: 15px;
        background: #6093DF ;
        border: 1px solid #6093DF ;
        font-size: 15px;
        line-height: 20px;
        border-radius: 10px;

        svg{
            font-size: 20px;
            margin-bottom: 10px;
            color: #fff;
        }

        span{
            color: #fff;
        }

    }
}

.inputDnD {
    position: relative;

    svg{
        font-size: 20px;
        color: #6fa9ff;
        position: absolute;
        z-index: 99;
        left: 44%;
        top: 16%;
    }

    .form-control-file {
      position: relative;
      width: 180px;
      min-height: 100px;
      outline: none;
      visibility: hidden;
      cursor: pointer;
      

      &:before {
        content: attr(data-title);
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            visibility: visible;
            text-align: center;
            overflow: visible;
            background: #ffffff;
            line-height: 110px;
            border: 2px dashed #9fc8ff;
            border-radius: 10px;
            font-size: 15px;
            color: #5483c7;
       
      }
      &:hover {
        &:before {

        }
      }
    }
  }

  .uld_details .css-1xc3v61-indicatorContainer{
    padding: 5px !important;
  }
  .uld_details .css-15lsz6c-indicatorContainer{
    padding: 5px !important;
  }
  .uld_details .css-13cymwt-control{
    min-height: 34px;
  }
  .uld_details .autoslct{
    min-height: 34px;
  }

  .uld_details .upfile{
    font-size: 13px;
    width: 100%;
  }
.ra_condition{
    .dropfile{
        padding: 20px 0;
        flex-wrap: wrap;
        gap: 10px;
    }
    .uploadfil{
        width: 13.7%;
        position: relative;

        img{
            max-width: 50px !important;
    max-height: 50px !important;
    margin-right: 0 !important;
        }

        .dltbtn{
            position: absolute;
            right: 0;
            background: none;
            top: 4px;

            svg{
                font-size: 14px;
            }
        }
    }
}

.rafilname{
    background: #fff;
    padding: 5px;
    width: 50%;
    margin: 0 auto;
    margin-top: 15px;
    p{
        margin-bottom: 0;

    }
}

.uld_modalcntrl{
    .can-toggle{
        position: inherit !important;
        width: 100px;
    }
}
.upimg_repair{
    margin-left: 15px !important;
    gap:10px;
    flex-wrap: wrap;
    .img-thumbnail{
        position: relative;
        button{
            position: absolute !important;
            top: -10px;
            right: -6px;
            z-index: 0;
            background: none;

            svg{
                font-size: 12px;
                color: #c1c1c1;
            }
            
        }
    }
}

.s_register{
    line-height: 39px;
    color: #000;
}

.s_nonregister{
    line-height: 39px;
    color: #2B2F36;
}
.main_setting{
    position: absolute;
    right: 10px;
    top: 8px;
    font-size: 13px;
    font-weight: 600;
    border: 1px solid #ddd;
    padding: 3px 5px;
    border-radius: 5px;
    background: #f3f3f3;

    a{
        color: #8d8d8d;
    }

    svg{
        color: #4da099;
        font-size: 14px;
    }
}
.movment_list{
    margin-top: 10px;
    ul{
        padding: 0;
        /* max-height: 215px;
        overflow: hidden; */
    }

    li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        background: #fff;
        box-shadow: 0px 3px 6px 2px #f1f1f1;
        padding: 8px 15px;
        border-radius: 4px;
        font-size: 13px;

        span.movem{
            font-weight: 600;
            width: 70px;
        }
        span.mvdate{
           
        }
        link{
            color: #0066ff;
            font-size: 13px;
           
        }
    }
}
.charter_top{
    .alltype{
        button{
            padding: 3px 10px;
        }
    }
}

.station_grid{
    .jqx-grid-pager > div{
        top: 4px !important;
        right: 15px;
    }
}

.downloadmd{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    label{
        font-size: 14px;
        line-height: 30px;
        width: 33.3%;
    }
}
.editculm{
    .autoslct{
        width: 100%;

        .css-13cymwt-control{
            border-color: #ffffff;
            box-shadow: 1px 5px 6px #DDE6FB;
        }
    }
    .css-1nmdiq5-menu {
        z-index: 999;
    }
    
}
.zoomcm{
    .modal-header{
        padding: 6px 0;
    }
    .nav-tabs{
        padding-bottom: 0;
    }
    .zoom_up{
        display: none;
    }
    .process_btn {
        margin-bottom: 15px;
    }
}
.racomment {
    body{
        background: none !important;
        padding-top: 10px;
    }
    figure.image{
        width: 100px;
        height: 100px;
    }
}
.imgedit{
    a{
        font-size: 13px;
        color: #8a9aad;
        margin-right: 10px;
    }
}
.uldlist{
    margin-top: 10px;
    box-shadow: 2px 1px 9px 0px #e0e7f7;
    border: 1px solid #e5edff;

    thead tr th{
        font-size: 13px;
        font-weight: 500;
        color: #282828;
    }
    
    tbody tr td{
        font-size: 13px;
        color: #363636;
    }
}
.table-responsive{
    overflow-x: inherit;
}
.ucm_h{
    min-height: 165px;
    scrollbar-width: thin;
}
.repar_h{
    min-height: 287px;
    scrollbar-width: thin;
}
.luc_h{
    min-height: 230px;
    scrollbar-width: thin;
}
.luc_area{
    .form-control{
        min-height: 350px;
    }
}

.bigpros{
    .form-control {
        min-height: 500px;
    }
}
.big_ucm{
    .form-control {
        min-height: 400px;
    }
}
.ra_repair{
    th{
        width: 124px;
    }
    td {
        .css-1fdsijx-ValueContainer {
            padding: 2px 4px 0px 4px;
    }
    }
}
.details_h{
    /* height: 44dvh;
    overflow: auto; */
}
.down_selct{
    border: none;
    height: 40px;
    font-size: 14px;

    .css-13cymwt-control{
        border-width: 0;
        box-shadow: 1px 5px 6px #DDE6FB;
        height: 40px;
    }
    .css-t3ipsp-control {
        min-height: 40px !important;
        border-width: 0;
        box-shadow: 1px 5px 6px #DDE6FB !important;
    }
}

.jqx-calendar-container{
    .jqx-calendar{
        box-shadow: rgb(221, 221, 221) 0px 5px 9px;
    }
}
.luct .form-control {
    min-height: 346px;
}
.transer_tble{
    th:nth-child(2){
        width: 100px;
    }
    th:nth-child(4){
        width: 100px;
    }
}
.lucinred > div{
    color: #e71414 !important;
    font-weight: 500;
}
.lucinorange > div{
    color: #ef771f !important;
    font-weight: 500;
}
.finalize{
    background: #79a4e9;
    color: #ffffff;
    border-radius: 3px;
    border: none;
    margin-top: 9px;
    padding: 5px 12px;
    font-size: 13px;
}
.mt-45{
    margin-top: 45px;
}
/* ++++++++++++++++++ ULD Control +++++++++++++++++++++++ */