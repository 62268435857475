@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
  font-family:'Segoe UI Light';
  src: url('/src/assets/fonts/Segoe UI Light.eot');
src: url('/src/assets/fonts/Segoe UI Light.eot?#iefix') format('embedded-opentype'),
  url('/src/assets/fonts/Segoe UI Light.woff2') format('woff2'),
  url('/src/assets/fonts/Segoe UI Light.woff') format('woff'),
  url('/src/assets/fonts/Segoe UI Light.svg#Segoe UI Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DC;
}

@font-face {
  font-family:'Segoe UI Semibold';
  src: url('/src/assets/fonts/Segoe UI Semibold.eot');
src: url('/src/assets/fonts/Segoe UI Semibold.eot?#iefix') format('embedded-opentype'),
  url('/src/assets/fonts/Segoe UI Semibold.woff2') format('woff2'),
  url('/src/assets/fonts/Segoe UI Semibold.woff') format('woff'),
  url('/src/assets/fonts/Segoe UI Semibold.svg#Segoe UI Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DC;
}

@font-face {
  font-family:'Segoe UI Semilight';
  src: url('/src/assets/fonts/Segoe UI Semilight.eot');
src: url('/src/assets/fonts/Segoe UI Semilight.eot?#iefix') format('embedded-opentype'),
  url('/src/assets/fonts/Segoe UI Semilight.woff2') format('woff2'),
  url('/src/assets/fonts/Segoe UI Semilight.woff') format('woff'),
  url('/src/assets/fonts/Segoe UI Semilight.svg#Segoe UI Semilight') format('svg');
  font-weight: 350;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family:'Segoe UI';
  src: url('/src/assets/fonts/Segoe UI.eot');
src: url('/src/assets/fonts/Segoe UI.eot?#iefix') format('embedded-opentype'),
  url('/src/assets/fonts/Segoe UI.woff2') format('woff2'),
  url('/src/assets/fonts/Segoe UI.woff') format('woff'),
  url('/src/assets/fonts/Segoe UI.svg#Segoe UI') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DC;
}

@font-face {
  font-family:'Segoe UI';
  src: url('/src/assets/fonts/Segoe UI Bold.eot');
src: url('/src/assets/fonts/Segoe UI Bold.eot?#iefix') format('embedded-opentype'),
  url('/src/assets/fonts/Segoe UI Bold.woff2') format('woff2'),
  url('/src/assets/fonts/Segoe UI Bold.woff') format('woff'),
  url('/src/assets/fonts/Segoe UI Bold.svg#Segoe UI Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DC;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}


html, body {
	line-height: 1;
  /* overflow-x: hidden !important; */
  font-family:'Segoe UI';
  width: 100%;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

input:focus-visible {
  outline: none;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fcfdff; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #c2cfff; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c2cfff; 
}
 /* END RESET */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.form-control:focus, .form-select:focus{
  outline: 0 !important;
  box-shadow: none !important;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.Toastify__toast--error {
  font-size: 14px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* 
.App-link {
  color: #61dafb;
} */



.logform, .loginform-left{
  width: 33% !important;
}


body{background: #f2f4ff !important;}
a{text-decoration: none !important;}

.loginform-left{
  background: url(assets/images/logo-bg.png) no-repeat left center;
  animation: test 1s ;
  background-size: cover;
}
.loginform-left span{

  animation: logoanim .4s 1.4s forwards;
  position: relative;
  left: -500px;
}
@keyframes logoanim {
  0% {left: -500px;opacity: 0;}
  50% {left: 5px;opacity: 1;}
  100% {left: 0px;opacity: 1;}
}
@keyframes test {
  0% {background-position: -500px center;}
  100% {background-position: left center;}
}

h2{margin-bottom: 28px;}
.logform, .loginform-left{
  width: 33% !important;
}

.mt-main {
  margin-right: 4.75%;
}
.form-outline .form-control.form-control-lg:focus~.form-label,.form-outline .form-control.form-control-lg.active~.form-label {
  transform: translateY(-1.25rem) translateY(0.1rem) scale(0.8)
}

.form-outline {
  position: relative;
  width: 380px;
}
.form-outline .form-control {
  min-height: auto;
  padding-top: 30px;
  padding-bottom: .82rem;
  padding-left: .75rem;
  padding-right: .75rem;
  border: 0;
  box-shadow: 0px 0px 10px #e3e3e3;
  transition: all .2s linear;
  font-size: .9rem;
}

.form-outline .form-control.form-control-sm {
  padding-top: .32rem;
  padding-bottom: .32rem;
  font-size: .775rem;
  line-height: 1.5
}

.form-outline .form-control.form-control-sm~.form-label {
  padding-top: .33rem;
  font-size: .775rem
}
.form-outline .form-control~.form-label {
  position: absolute;
  top: 20px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  left: .75rem;
  /* padding-top: .37rem; */
  pointer-events: none;
  transform-origin: 0 0;
  transition: all .2s ease-out;
  color: var(--mdb-form-control-label-color);
  margin-bottom: 0;
}

.form-outline .form-control.form-control-sm:focus~.form-label,.form-outline .form-control.form-control-sm.active~.form-label {
  transform: translateY(-0.85rem) scale(0.8);
  /* translateY(0.1rem) */
}

.justify-content-space {
  justify-content: space-between !important;
  max-width: 380px;
}
.form-check {
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5em;
  margin-bottom: .125rem;
}

.form-check-label, .text-forgot{font-weight: 500;}
.text-forgot{color:#66676c}
.btn-primary{background: #1754af !important; font-size: .9rem !important;}

/* header */
.left-section{display: flex;align-items: center;}
.logo-main {/*margin-left: 15px;*/}
.logo-main img{/*width:32%;*/}
.header-main{    
  padding: 10px 25px 10px 25px;
  justify-content: space-between;
  position: relative;
}
.navbar-nav {
  display: flex !important;
  flex-direction: row !important;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  position: relative;
}
.dropdown button{text-decoration: none;color: #000; font-size: 0.8rem; line-height: 33px;}
.nav-profile button{display: flex;align-items: center; color: #0b796d;}
.nav-profile button:active{color: #0b796d !important;}
.navbar-nav-right .top-grid-ico{border: none;background: none;}
.nav-profile button > span{text-align: right;}
.nav-profile a.dropdown-item{
  font-size: 14px;
}
.nav-profile .success{
  color: #0b796d !important;
}
.nav-profile .prof-img{
  color: #0b796d !important;
}
.nav-profile .preview-subject{
  margin-bottom: 2px;
  font-size: 15px;
}
.nav-profile button span.prof-img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  border: 1px solid #0b796d;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;display: flex;
  justify-content: space-around;
}
.dropdown button::after{display: none;}
.switch button{line-height: 33px; color: #fff;}
.switch button svg{margin-right: 4px;}
.nav-link{padding: 0 !important;}

/* left nav */
nav.sidebar{
  width: 100px;
  margin-left: -100px;
  transition: margin-left 0.25s ease;
  -webkit-transition:margin-left 0.25s ease;
  -moz-transition:margin-left 0.25s ease;
  -ms-transition:margin-left 0.25s ease;
}
body.sidebar-icon-only .dashboard nav.sidebar{
  margin-left: 0px;
}

#sidebar ul.nav li{width: 100%; display: block;}
#sidebar ul{padding: 0;}
#sidebar li{margin-bottom: 8px;}
#sidebar li a{
  font-size: 2rem; padding:20px 0 20px 10px !important; 
  background: #fff; display: block;
  box-shadow: 0 0 5px 2px #e5e5e5;
  position: relative;
}
#sidebar li a span{display: block; font-size: 0.8rem;}
#sidebar li a span.act-sign{
  display: none;
  position: absolute;
  right: 0;
  width: 3px;
  height: 50%;
  background: #2866C2;
  top: 25%;
}
#sidebar li a.active span.act-sign{display: block;}

/* TOP MENU */
ul.top-nav a{display: flex;}
ul.top-nav a > span:first-child{color: #6f7074; margin-right: 10px;}
ul.top-nav a > span:last-child span:last-child{margin-top: -7px;}
ul.top-nav a span {color: #0c0c0c; display: block;}
ul.top-nav a span svg{font-size: 40px;}

/* Dashboard */

.main-panel{
  margin: 0 25px;
  width: calc(100% - 50px);
}
.main-panel .content{background: #fff; padding: 10px; /*height: calc(100vh - 14px);*/box-shadow: 0 5px 10px #D6E4F8;}
body.sidebar-icon-only .main-panel{width: calc(100% - 40px);}
/* GRID */
.jqx-grid{border-width: 0 !important;}
.jqx-grid-content > div > div{border-bottom:1px solid #e8e8e8;}
.jqx-grid-content > div > div:hover{background: #f5f5f5;}
.jqx-grid-cell-sort-alt, .jqx-grid-cell-sort{background: #f5f5f5;}
.jqx-grid-cell-alt{background: #fff !important;}
.jqx-grid-cell-filter-row{border-width: 0 !important; background: #eff1f4 !important;}
.jqx-fill-state-pressed{background: #f5f5f5 !important;}
.jqx-widget-header{background: #fff !important; border-width: 0 !important;}
.jqx-fill-state-hover{background: transparent !important;}
.jqx-grid-cell{border-width: 0 !important; }
div[role="row"] > div:not(:first-child){border-width: 0 !important; background: transparent !important;}

.createplan{
  background: #2866C2;
  border-radius: 5px;
  border: none;
  color: #fff;
  padding: 7px 22px;
  margin-bottom: 12px;
  display: inline-block;
}
.createplan svg{
  margin-right: 5px;
}

/* CREATE PLAN */

.create-plan{width: 95% !important;}

/* TABS MAIN */
.mainTab ul, .chart-panel ul{border: none;}
.nav-tabs button span svg{font-size: 1.8rem;color: #787878; margin-right: 10px;}
.mainTab > .nav-tabs button span span, .mainTab > .chart-panel button span span
{max-width: 82px; display: inline-block; text-align: left;font-size: 0.8rem;line-height: 1rem;color: #000;}
.mainTab > .nav-tabs .nav-link,
.chart-panel > .nav-tabs .nav-link{
  background: #fff; 
  margin-right: 10px; 
  border-radius: 0.375rem; 
  padding: 10px !important;
  border: 1.5px solid #E9E9E9;
  width: 154px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    opacity: 0.7;
}
.mainTab > .nav-tabs .nav-link.active,
.chart-panel > .nav-tabs .nav-link.active{border: 1.5px solid #1754af !important; opacity: 1;}
.mainTab ul li a span{
  font-size: 14px;
}
.allcontent-fl{position: relative;}
.allcontent-fl .rcontent{
  position: absolute;
  right: 10px;
  top: 62px;
}

.hmenu {
  /* padding-left: 30px;
    margin-bottom: 15px; */
    line-height: 45px;
    margin-right: 15px;
    /* margin-left: 20px; */
}
.hmenu svg{
  font-size: 20px;
  font-weight: 100;
}

.nav-profile .dropdown-menu{
  box-shadow: 0 5px 10px #D6E4F8;
  border: none;
  position: absolute !important;
  inset: inherit !important;
  transform: translate(0px, 10px) !important;
  right: 0px !important;
}
/* SUB TABS */

.nestedtabs{
  margin-bottom: 5px !important;
  background: #fff;
  box-shadow: 0 5px 10px #D6E4F8;
  padding: 10px 15px;
}
.minh{
  min-height: 60px;
}

.nestedtabs li{
  margin-right: 10px;
  margin-bottom: -10px;
}

.nestedtabs li button{
  padding-bottom: .8rem !important;
  background: transparent !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  font-size: 15px;
  font-weight: 500;
  color: #8F8F8F;
  padding-left: 18px !important;
  padding-right: 18px !important;
}
.nestedtabs li button span span{
  margin-left: 5px;
    padding: 5px;
    border-radius: 5px;
}
.nestedtabs li:nth-child(1) button span span{background: #d5e9d9;}
.nestedtabs li:nth-child(2) button span span{background: #fcf4d6;}
.nestedtabs li button span span{background: #f4f5f8;}
.nestedtabs li button.active{border-width: 0 0 3px; border-style:  solid;border-color:  #2866C2 !important;}

.rcontent{font-size: 0.8rem;}
.rcontent button{
  border: none; 
  background:none; 
  color: #6B748B;
  font-size: 14px;
  padding: 0 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 26px;
}
.rcontent button img{
  margin-right: 10px;
}

.super-awesome-shadow {
  position: fixed;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.4;
  z-index: 991;
  top: 0;
}
.super-awesome-modal {
  /* position: absolute; */
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30%;
  height: 40%;
  background-color: #fdfdfd; */
  border-radius: 12px;
  /* box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.3); */
  z-index: 999;

  position: fixed;
  left: 50%;
  top: 50%;
  right: auto;
  transform: translate(-50%, -50%);
  width: 20rem;
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0rem 0rem 1rem rgba(37, 38, 41, 0.12);
  padding: 2.25rem 2rem;
}


/* chart page */

.chart-panel{margin-left: 20px;width: calc(100% - 125px);}
.chart-panel .rounded .row{margin-bottom: 25px;}
.cartbox{padding: 10px; background: #fff;border-radius: 10px; width: 100%;max-height: 300px;}

/* Stepper */

.st-f{display: flex;}
.raroot .MuiStepLabel-alternativeLabel{flex-direction: row !important;}
.rasteper{
  display: block !important; 
  width: 23%;
  background: #2866c2;
  padding: 40px 0px 40px 20px;
  border-radius: 10px 0 0 10px;

}
.stepper-right{
  width: 77%;
  background: #E4EAFF; 
  padding: 40px 0; 
  display: flex;
  border-radius: 0 10px 10px 0;}

.typemodal .modal-body{padding:0 0 48px !important;}
.typemodal .modal-100w{width: 450px !important;}
.typemodal .modal-header{border-bottom: none !important;}
.typemodal .modal-body h4{text-align: center;font-size: 20px;color: #636363;font-weight: 400;}
.typemodal .modal-body .cr-pln-btns{display: flex; justify-content: center;margin-top: 20px;}
.typemodal .modal-body .cr-pln-btns a{
  text-align: center; background: #e8e8e8; color: #464646; 
  padding: 10px 10% 18px; border-radius: 5px; margin: 0 10px;
}
.typemodal .modal-body .cr-pln-btns a:hover{background:#2866c2; color: #fff;}

.raroot{padding-top: 8px; padding-bottom: 8px; margin-bottom: 45px;}
.raroot .MuiStepLabel-alternativeLabel {text-align: left !important;}
.raroot .MuiStepLabel-alternativeLabel{color:#94b3e1;}
.raroot .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root{fill:#94b3e1 !important;}
.raroot .MuiStepLabel-alternativeLabel{margin-top: 4px !important; margin-left: 15px;}

.raroot .MuiStepConnector-root{    
  top: -53px !important;
  left: 57px !important;
  width: 5px;
}
.raroot .MuiStepConnector-line{
  width: 2px;
  height: 68px;
  background: #6b95d5;
}
.raroot .MuiStepLabel-alternativeLabel .Mui-active, .raroot .MuiStepLabel-alternativeLabel .Mui-completed {
  color: #fff !important; font-weight: 400 !important;
}
/* .css-zpcwqm-MuiStepConnector-root.Mui-active .css-z7uhs0-MuiStepConnector-line,
.css-zpcwqm-MuiStepConnector-root.Mui-completed .css-z7uhs0-MuiStepConnector-line{background: #fff;}
 
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
.css-1hv8oq8-MuiStepLabel-label.Mui-active,
.css-1hv8oq8-MuiStepLabel-label.Mui-completed{fill:#fff !important;color: #fff !important; font-weight: 400 !important;}
.css-117w1su-MuiStepIcon-text{fill:#000 !important;}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root{
  width: 37px !important;
  height: 37px !important;
} */

.stepper-frms{
  width: 72%;
  border-right: 2px solid #dae2ff;
  padding: 0px 15px 0 20px;
position: relative;

  h4{
    font-size: 15px;
    font-weight: 400;
    color: #959595;
  }
  h2{
    font-size: 18px;
    font-weight: 500;
    color: #212121;
    margin-bottom: 25px;
  }
}
.stepper-frm-info{
  width: 28%;
  padding: 0 10px;
  max-height: 630px;
  overflow: auto;

}
.stepper-frms .form-group{
  margin-bottom: 15px;
}
.stepper-frms .form-label{
  font-size: 14px;
  color: #2B2F36;
font-weight: 600;
  margin-bottom: 8px;
}
.stepper-frms .form-control{
  padding: 0.495rem 0.75rem;
      border-radius: 6px;
      box-shadow: 0px 5px 5px #DCE3FE;
      margin-bottom: 10px;
      border: none;
}
.stepper-frms .form-control::placeholder{
  font-size: 13px;
}
.stepper-frms .form-select{
  padding: 0.495rem 0.75rem;
      border-radius: 6px;
      box-shadow: 0px 5px 5px #DCE3FE;
      margin-bottom: 10px;
      border: none;
      padding-right: 32px;
}
.stepper-frms .form-select option{
  font-size: 13px;
}
.mr40{
  margin-right: 40px;
}
.form-check label {
  display: inline;
  margin-right: 25px;
  font-size: 14px;
  color: #646464;
font-weight: 400;
}

.addshipment{
  background: #fff;
  border-radius: 5px;
  padding: 15px 15px;
  margin-bottom: 20px;
  position: relative;
}
.airbill{
  gap: 10px;
}
.airbill label{
  font-size: 12px !important;
  color: #2B2F36;
font-weight: 400;
margin-bottom: 6px;
}
.airbill .form-control, .airbill .form-select{
height: 28px;
border: 1px solid #D9D9D9 !important;
border-radius: 4px !important;
font-size: 13px;
padding: 5px;
box-shadow: none !important;
margin-bottom: 0 !important;
padding: 0.295rem 0.45rem !important;
}

.raintrac .form-select{
  height: 35px;
  width: 180px;
border: 1px solid #D9D9D9 !important;
border-radius: 4px !important;
font-size: 13px;
color: #707070;
padding: 5px;
box-shadow: none !important;
margin-bottom: 0 !important;
padding: 0.295rem 0.45rem !important;
}

.housbill{
  background: #F1F1F1;
  width: 60%;
  padding: 10px 10px;
  gap: 10px;
  align-items: end;
  border-radius: 5px;
  margin-bottom: 10px;
}


.housbill label{
  font-size: 12px !important;
  color: #2B2F36;
font-weight: 400;
margin-bottom: 6px;
line-height: 1;
}
.housbill input, .housbill select{
  height: 32px;
  background: #fff !important;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  font-size: 13px;
      padding: 5px;
  }
  .addbtn{
    background: #71c4ff  !important;
    padding: 6px 15px !important;
    border-color: #71c4ff  !important;
    border-radius: 4px;
  }

  .addshipbtn{
    background: #369AE4 !important;
    padding: 6px 15px !important;
    border-color: #369AE4 !important;
    border-radius: 4px;
  }
  .addmawb{
    background: #6978DC !important;
    padding: 6px 15px !important;
    border-color: #6978DC !important;
    border-radius: 4px;
  }
  .nxtbtn{
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 25px;
  }
  
  .addshipment .form-control{
    box-shadow: none !important;
    padding: 5px !important;
    margin-bottom: 0 !important;
    border-radius: 4px !important;
    font-size: 12px !important;
    border: 1px solid #D9D9D9;
    background: #FBFBFB;
    height: 28px;
  }

.raclose{
  position: absolute;
  top: 4px;
  right: 10px;
}

  .scrollable-calendar {
    display: flex;
    white-space: nowrap;
    gap:10px;
  }
  
  .day {
    width: 100px;
    text-align: center;
    padding: 10px 0;
    border: 1px solid #1654b052;
    background: #ffffffc9;
    border-radius: 10px;
  }
  .day.current-date{
    border: 1px solid #CBCBCB;
    background: #FBFCFF;
    color: #fff;
  }
  .day.current-date > .date{
    color: #B2B2B2;
  }
  .day.current-date > .day-name{
    color: #B2B2B2;
  }
  .day.current-date > .slot{
    color: #B2B2B2;
  }
  .date {
    font-weight: bold;
    color: #2866C2;
    font-size: 18px;
    margin: 6px 0;
  }
  
  .day-name {
    font-size: 14px;
    color: #212121;
  }
  .slot{
    font-size: 13px;
    color: #287fff;
  }
  .slot span{
    background: #1AB955;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 15px;
  }
  .btnright{
    padding: 7px 18px;
    border-radius: 5px;
    box-shadow: 0px 6px 8px #ced8f9;
    border: 1px solid #c3d0ff;
    color: #5182cb;
    margin: 0 8px;
  }
  .btnleft{
    padding: 7px 18px;
    border-radius: 5px;
    box-shadow: 0px 6px 8px #ced8f9;
    border: 1px solid #c3d0ff;
    color: #9f9f9f;
    margin: 0 8px;
  }
  .iconcer{
    width: 40px;
    height: 40px;
    background: #ffffff82;
    border-radius: 50px;
    text-align: center;
    line-height: 35px;
  }
.Mui-active .iconcer{
  background: #fff !important;
}
.Mui-completed .iconcer{
  background: #23C175 !important;
  position: relative;
}
.Mui-completed .iconcer > img{
  display: none;
}
.Mui-completed .iconcer::after{
content: "";
background-image: url(../src/assets/images/tick.png);
background-repeat: no-repeat;
width: 19px;
height: 13px;
position: absolute;
left: 11px;
top: 14px;
}

.raroot.done .iconcer{
  background: #23C175 !important;
  position: relative;
}
.raroot.done .iconcer > img{
  display: none;
}
.raroot.done .iconcer::after{
  content: "";
  background-image: url(../src/assets/images/tick.png);
  background-repeat: no-repeat;
  width: 19px;
  height: 13px;
  position: absolute;
  left: 11px;
  top: 14px;
  }
  .raroot.done .MuiStepLabel-alternativeLabel{
    color: #ffffff;
  }
 .Mui-active .MuiStepConnector-line{
    background: #ffffff;
  }
  .raroot.done .MuiStepConnector-line{
    background: #ffffff;
  }

.housbill button, .addshipment button{
  box-shadow: none;
    border: none;
    padding: 0;
    background: inherit;
    font-size: 14px;
}
.rascroll{
  max-height: 510px;
    overflow: auto;
}
.avalable h2{
  font-size: 16px;
  color: #2B2F36;
  font-weight: 600;
  margin-bottom: 15px;
}
.timeslot{
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
}
.timeslot .time{
  width: 122px;
  text-align: center;
  padding: 6px 0;
  border: 1px solid #1654b052;
  background: #ffffffc9;
  border-radius: 6px;
  cursor: pointer;
}
.timeslot .time.unavailable{
  border: 1px solid #cbcbcb;
  background: #fbfcff;
  color: #b2b2b2;
}
.dropfile{
  border: 2px dashed #b3b3b3;
  padding: 80px 0;
  margin-bottom: 20px;
  background: #ffffffbd;
  border-radius: 10px;
  text-align: center;
}
.dropfile h3{
font-size: 15px;
color: #646464;

}
.dropfile p{
  color: #000000;
  font-size: 14px;

}
.dropfile span{
  display: block;
  font-size: 13px;
  color: #AEAEAE;
  margin-top: 15px;
}
.drpbtn{
  background: #30A8FF !important;
  border-color: #30A8FF !important;
}
.dltbtn{
  box-shadow: none;
  border: none;
  padding: 0;
  background: inherit;
}
.uploadbox{
display: flex;
flex-wrap: wrap;
gap: 15px;
}
.uploadfil{
  width: 31.7%;
  margin-bottom: 0;
  background: #F4F7FF;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.fileimg{
  width: 30px;
  margin-right: 10px;
}

.infoarm{
  background: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  box-shadow: 0px 4px 6px #ced8f9;
}
.infoarm:nth-child(6), .infoarm:nth-child(7), .infoarm:nth-child(8){display: none;}
.uldno{
  color: #525252;
  display: block;
}
.infobox .card-body{
  padding: 8px;
}
.infobox .card{
  margin-bottom: 10px;
}
.infobox .card-header{
  font-size: 14px;
  color: #2866c2;
  font-weight: 600;
}
.infobox h2{
  font-size: 14px;
  color: #2866C2;
}
.infobox ul{
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0;
}
.infobox ul li span:first-of-type{
  font-size: 13px;
  color: #717171;
}
.infobox ul li span:last-of-type{
  font-size: 13px;
  color: #6ea0eb;
}
/* .rainfobox{
  border-bottom: 2px solid #e4eaff;
  margin-bottom: 10px;
  padding-bottom: 10px;
} */
/* .infobox ul ul {
  padding-left: 15px;
} */
.billtable{
  margin-top: 10px;
}
.billtable table{
  margin-bottom: 0;
}
.billtable table th{
  font-size: 11px;
  color: #2a2a2a;
  background: #e4eaff;
  padding: 2px;
}
.billtable table tr td{
  font-size: 13px;
  color: #717171;
  padding: 5px;
}

.complt img{
  margin-bottom: 25px;
}
.complt h3{
  text-align: center;
  font-size: 28px;
  color: #333333;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0;
}
.complt p{
  text-align: center;
  font-size: 16px;
  color: #333333;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0;
}
.raintrac{
  display: flex;
  gap: 10px;
}
.raintrac .form-floating>.form-control{
  height: 35px;
  min-height: 35px;
}
.raintrac .form-floating>label{
  font-size: 12px !important;
  margin-bottom: 0 !important;
  padding: 5px 10px;
}
.raintrac label span{
  color: #E33232;
  font-size: 14px !important;
}
.raintrac .form-floating>.form-control:not(:placeholder-shown)~label::after{
  top: -10px;
}
/* jqx START */

.jqx-widget-header{
  font-size: 14px !important;
  color: #2866C2;
  font-family: "Roboto", sans-serif;
  font-weight: 500 !important;
}

/* .jqx-grid-content > div > div{
  height: 42px !important;
}
.filter.jqx-fill-state-normal{
  height: 29px !important;
} */
/* .rounded .jqx-fill-state-normal{
  border-color: #ffffff !important;
  background: #ffffff !important;
} */
.jqx-checkbox-default{
  border-color: #9d9c9c !important;
    background: #ffffff !important;
    border-radius: 0 !important;
}
.jqx-fill-state-focus{
  border-color: #c9c9c9 !important;
}
.jqx-fill-state-pressed{
  border-color: #fff !important;
}
.jqx-grid-cell-left-align{
  font-family: "Roboto", sans-serif !important;
  font-size: 14px ;
  /* margin-top: 12px !important; */
  color: #2B2F36 !important;
}
.jqx-widget-content{
  border-color: #ffffff !important;
}
.jqx-listbox{
  box-shadow: 0px 1px 4px 3px #e3e3e3;
}
.jqx-dropdownlist-content{
  color: #878787;
}
.jqx-grid-group-collapse{
  background-color: #ffffff !important;

}
.jqx-dropdownlist{
  border-color: #fff !important;
    background: #ffffff !important;
}
.jqx-grid-cell-pinned {
  background-color: #ffffff !important;
}
.jqx-grid-cell-filter-row{
  background-color: #f2f4ff !important;
}
.jqx-enableselect .jqx-grid-header .jqx-grid-column-header{
  background: #F9F9F9 !important;
  color: #232323;
}
.jqx-grid-pager-input{
  border: 1px solid #ccc !important;
}
.jqx-grid-pager > div{
  top: 11px !important;
}
.jqx-grid-pager .jqx-dropdownlist > div > div{
  border: 1px solid #ccc !important;
}
.ragap{
  gap: 10px;
}
.housbilltxt{
  display: flex;
  width: 85%;
  justify-content: start;
  margin-left: 24px;
}
.housbilltxt label:nth-child(1){
  margin-right: 40px;
}
.housbilltxt label:nth-child(2){
  margin-right: 45px;
}

.ralink{
  line-height: 45px;
  margin-right: 15px;
}
.accordion-header .accordion-button{
  border-radius: 5px;
  border-bottom: 1px solid #fdfdfd;
}
.accordion-button{
  padding: 10px !important;
  background: #fff !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  color: #2B2F36 !important;
}
.accordion-button:focus{
  box-shadow: none !important;
}
.accordion-body{
  padding: 5px !important;
}
.accordion-header .arrow.collapsed::after{
  transform: rotate(-90deg);
}
.accordion{
  margin-bottom: 10px;
}
/* jqx END */

.settingbg{
  margin-bottom: 20px ;
    background: #fff;
    box-shadow: 0 5px 10px #D6E4F8;
    padding: 20px;
    border-radius: 10px;
    height: 85dvh;
    position: relative;
}
.settingbg_right{
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 5px 10px #D6E4F8;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  height: 85dvh;
  overflow: auto;
}
.raswitch .form-check-input{
  width: 2.8em !important;
  height: 1.5em;
}
.profile{
  text-align: center;
}
.profile h3{
  font-size: 18px;
  color: #212121;
  font-family: "Roboto", sans-serif !important;
  margin: 15px 0 0 0;
}
.profile p{
  color: #919191;
  font-size: 14px;
  font-family: "Roboto", sans-serif !important;

}
.settingbg ul {
  padding: 0;
  margin-bottom: 0;
  margin-top: 20px;
}
.settingbg ul li{

}
.settingbg ul li:hover{
  background: #ECF1FB;
}
.settingbg ul li.active{
  background: #ECF1FB;
}
.settingbg ul li a{
  font-size: 14px;
  color: #212121;
  font-weight: 500;
  font-family: "Roboto", sans-serif !important;
  line-height: 30px;
  padding: 12px 10px;
  border-bottom: 1px solid #e7ecff; 
  display: block;

  img{
    margin-right: 10px;
  }
}
.settingbg ul li a span{
  width: 32px;
  display: inline-block;
}
.settingbg_right h4{
 color: #212121;
 font-size: 17px;
 font-family: "Roboto", sans-serif !important;
 margin-bottom: 5px;
}

.settingbg_right h3{
  color: #212121;
  font-size: 15px;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 0;
 }
 .settingbg_right h6{
  color: #212121;
  font-size: 15px;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 0;
 }

.settingbg_right p{
  color: #919191;
  font-size: 13px;
  font-family: "Roboto", sans-serif !important;
 }
 .settingbg_right .card .card-header {
  background: #2866C2;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif !important;
 }
 .settingbg_right .card .form-check {
  margin-bottom: 0.8rem;
 }
 .settingbg_right .card .form-check label{
  color: #535353;
  font-size: 14px;
  font-family: "Roboto", sans-serif !important;
  font-weight: 500;
 }
 .settingbg_right .card-text{
  font-size: 13px;
  font-family: "Roboto", sans-serif !important;
  color: #919191;
 }

 .setting_switch h3{
  color: #212121;
  font-size: 16px;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 0;
 }
 .setting_switch p{
   color: #919191;
   font-size: 13px;
   font-family: "Roboto", sans-serif !important;
   margin-bottom: 0;
  }
  .settingbg_right .card-header .form-check{
    margin-bottom: 0;
    margin-right: 0;
  }
  .settingbg_right .card-header .form-check label{
    color: #fff;
  }
  .switch .dropdown-menu{
    width: 330px;
    padding: 15px 0;
    border: none;
    box-shadow: 0px 5px 10px 2px #D7E2F9;
    max-height: 380px;
    overflow: auto;
    position: absolute !important;
    inset: inherit !important;
    transform: translate(0px, 10px) !important;
    right: 0px !important;
  }

.notify h2{
  color: #2C365A;
  font-size: 18px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif !important;
  font-weight: 500;
}
.notify button{
  background: #5D87FF !important;
  line-height: 1;
  font-size: 12px !important;
  border-radius: 15px;
}
.switch .dropdown-header{
  padding-bottom: 20px;
}
.notifyimg img{
  height: 44px;
  width: 44px;
  border-radius: 50px;
  margin-right: 15px;
}
.notifytxt p{
  margin-bottom: 0;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  width: 237px;
}
.notifytxt p small{
  color: #878787;
  font-size: 14px;
}
.seall{
width: -webkit-fill-available;
margin: 9px 15px 0 15px;
color: #5D87FF !important;
font-family: "Roboto", sans-serif !important;
font-weight: 500;
font-size: 16px;
}
.seall:hover{
  color: #fff !important;
  background: #5D87FF !important;
}
.switch .dropdown-item{
  padding: 8px 15px;
}
.dropdown-item.active, .dropdown-item:active{
  background-color: #2866c2 !important;
}
.allmodule .dropdown-menu{
  width: 330px;
  padding: 15px;
  box-shadow: 0px 5px 10px 2px #CECECE;
  max-height: 380px;
  overflow: auto;
  border: 5px solid #F2F5FC;
  border-radius: 20px;
  position: absolute !important;
  inset: inherit !important;
  transform: translate(0px, 10px) !important;
  right: 0px !important;

}
.allmodule_fl{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
.allmodule_fl .dropdown-item{
  width: 30.3%;
  text-align: center;
  padding: 10px 6px;
  border-radius: 10px;
}
.allmodule_fl .dropdown-item p{
  margin-bottom: 0;
  font-size: 12px;
  color: #4D4D4D;
  font-family: "Roboto", sans-serif !important;
  margin-top: 10px; 
}
.allmodule_fl .dropdown-item:active{
  background-color: #f2f4ff !important;
}
.mawb{
  position: relative;
  margin-bottom: 20px;
  .raclose{
    position: absolute;
    top: 30px;
    right: 0px;
    button{
      width: 30px;
    height: 30px;
    background: #e4eaff;
    border-radius: 20px;
    }
    svg{
      color: #8c9bd1;
      font-size: 12px;
    }
  }
}
.raintrac{
  .modal-content{
    padding: 20px;
  }
  .modal-header{
    padding: 0;
    border: none;
  }
  .btn-close{
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .modal-body{
    padding: 10px 0 !important;
    text-align: center;
  }
  .modal-body h2{
    font-size: 20px;
    color: #2866c2;
    font-weight: 400;
    font-family: "Roboto", sans-serif !important;
  }
  .modal-body p{
    margin-bottom: 0;
    font-size: 14px;
    color: #282828;
  }
  .modal-footer{
    justify-content: center;
    border: none;
    .btn{
      width: 70px;
    }
    .btn-secondary{
    
    }
    .btn-primary{
      background: #2866C2 !important;
    }

  }
}

.rmsc .dropdown-container{
  border: none !important;
}
.rmsc .dropdown-heading-value{
font-size: 14px !important;
}

.rastp{
  font-size: 13px;
    font-weight: 400;
    color: #959595;
}
.important{
  color: #E33232;
  font-size: 14px !important;
}

.rounded .jqx-widget-content .jqx-enableselect{
  z-index: 999 !important;
}

.day.active{
  border: 2px solid #589bff;
  background: #fff;
}
.available.selected{
  border: 2px solid #589bff;
  background: #fff;
}

.row_edit{width: 16px;cursor: pointer;} 
.row_delete{width: 16px;cursor: pointer;}
.grid_actions{
  height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.stepper-frms .form-label span{
  color: #E33232;
  font-size: 14px !important;
}
.order_search{
  position: absolute;
  right: 0;
  top: -50px;
}



